import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import dayjs from "dayjs";

export type PrivatePolicyStatusGroup = "active" | "submitted" | "expired";
export type PrivatePolicyReviewStatus = "accepted" | "rejected" | "inReview";
export type PrivatePolicyStatus = "accepted" | "expiringSoon" | "expired" | "inReview" | "rejected";

export const getCalculatedPrivatePolicyStatus = (
  privatePolicy: SafeleasePrivatePolicy,
): {
  statusGroup: PrivatePolicyStatusGroup;
  reviewStatus: PrivatePolicyReviewStatus;
  status: PrivatePolicyStatus;
  isExpired: boolean;
  isRecentlyRejected: boolean;
  isEnrollmentEnabled: boolean;
} => {
  const diffInDays = dayjs(privatePolicy.expiration).diff(dayjs(), "day");

  const reviewStatus = ["accepted"].includes(privatePolicy.validationStatus)
    ? "accepted"
    : ["rejected", "pendingRejected"].includes(privatePolicy.validationStatus)
      ? "rejected"
      : "inReview";

  const statusGroup = reviewStatus !== "accepted" ? "submitted" : diffInDays > 0 ? "active" : "expired";

  const status =
    reviewStatus === "rejected"
      ? "rejected"
      : reviewStatus === "inReview"
        ? "inReview"
        : reviewStatus === "accepted" && diffInDays > 30
          ? "accepted"
          : reviewStatus === "accepted" && diffInDays < 0
            ? "expired"
            : "expiringSoon";

  const isRecentlyRejected =
    reviewStatus === "rejected" &&
    ((dayjs().diff(dayjs(privatePolicy.createdAt), "day") < 60 && dayjs().diff(dayjs(privatePolicy.rejectedAt), "day") < 14) ||
      (dayjs().diff(dayjs(privatePolicy.createdAt), "day") >= 60 && dayjs().diff(dayjs(privatePolicy.rejectedAt), "day") < 3));

  return {
    statusGroup,
    reviewStatus,
    status,
    isExpired: diffInDays < 0,
    isRecentlyRejected,
    isEnrollmentEnabled: ["active", "submitted"].includes(statusGroup) && !(reviewStatus === "rejected" && !isRecentlyRejected),
  };
};

export const getSafeleasePlanName = (
  protectionPlans: { coverage: number; premium: number }[],
  plan: { coverage: number; premium: number },
) => {
  if (!protectionPlans || protectionPlans.length === 0 || protectionPlans.length > 4) return "";

  if (protectionPlans.length === 2) {
    if (plan.premium === protectionPlans[0].premium && plan.coverage === protectionPlans[0].coverage) return "Standard";
    if (plan.premium === protectionPlans[1].premium && plan.coverage === protectionPlans[1].coverage) return "Premium";
  }

  if (protectionPlans.length === 3) {
    if (plan.premium === protectionPlans[0].premium && plan.coverage === protectionPlans[0].coverage) return "Standard";
    if (plan.premium === protectionPlans[1].premium && plan.coverage === protectionPlans[1].coverage) return "Preferred";
    if (plan.premium === protectionPlans[2].premium && plan.coverage === protectionPlans[2].coverage) return "Premium";
  }

  if (protectionPlans.length === 4) {
    if (plan.premium === protectionPlans[0].premium && plan.coverage === protectionPlans[0].coverage) return "Basic";
    if (plan.premium === protectionPlans[1].premium && plan.coverage === protectionPlans[1].coverage) return "Standard";
    if (plan.premium === protectionPlans[2].premium && plan.coverage === protectionPlans[2].coverage) return "Preferred";
    if (plan.premium === protectionPlans[3].premium && plan.coverage === protectionPlans[3].coverage) return "Premium";
  }

  return "";
};
