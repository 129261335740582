import { IconButton, Stack, Theme } from "@mui/material";
import { Typography, Modal } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useEffect, useState } from "react";
import { privatePolicyFormStyles } from "./PrivatePolicyForm";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { zodResolver } from "@hookform/resolvers/zod";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import { z } from "zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { StyledSafeLeaseFormTextField } from "../shared/StyledSafeLeaseFormTextField";
import { usePrivatePolicyFormStore } from "./usePrivatePolicyFormStore";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { enqueueSnackbar } from "notistack";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";
import useMixpanel from "../hooks/useMixpanel";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

export type PasswordlessSignUpFormValues = {
  phone: string;
  facilityName: string;
  unit: string;
  moveInDate: Date | null;
  description: string;
};

const validationSchema = z.object({
  phone: z
    .string()
    .trim()
    .min(1, "Phone number is required.")
    .refine((data) => isPossiblePhoneNumber(data, "US"), "Invalid phone number."),
  facilityName: z.string().trim().min(1, "Facility name and address is required."),
  unit: z.string().trim().min(1, "Unit is required."),
  description: z.string().trim().min(1, "Description is required."),
  moveInDate: z.date().nullable(),
});

interface NeedHelpComponentProps {
  highlight?: boolean;
}

function NeedHelpComponent({ highlight = false }: NeedHelpComponentProps) {
  const { privatePolicyFormRef, needHelpModalOpen, setNeedHelpModalOpen, currentPrivatePolicySubmissionStep } = usePrivatePolicyFormStore(
    (state) => state,
  );
  const { mixpanelTrack } = useMixpanel();

  const [height, setHeight] = useState<number | "fit-content">(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const useFormMethods = useForm<PasswordlessSignUpFormValues>({
    defaultValues: {
      facilityName: "",
      unit: "",
      moveInDate: null,
      description: "",
      phone: "",
    },
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    shouldFocusError: true,
    reValidateMode: "onChange",
  });
  const { formState, handleSubmit, watch, setValue, control } = useFormMethods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const request = {
        location: data.facilityName,
        unit: data.unit,
        moveInDate: data.moveInDate,
        description: data.description,
        phone: data.phone,
      };

      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_SUBMITTED, request);

      setLoading(true);
      const response = await ClaimsApi.submitSupportRequest(request);

      if (!response.data.success) {
        throw new Error("Failed to submit support request");
      }

      setSuccess(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to submit support request", { variant: "error" });
    } finally {
      setLoading(false);
    }
  });

  const handleClose = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_DISMISSED);
    setNeedHelpModalOpen(false);
  };

  const handleNeedHelpButtonClick = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_BUTTON_CLICKED, {
      currentPrivatePolicySubmissionStep,
      referrer: "Need Help Button",
    });
    setNeedHelpModalOpen(true);
  };

  // Set the height of the modal to the height of the private policy form
  useEffect(() => {
    setHeight(privatePolicyFormRef?.current?.clientHeight ?? "fit-content");
  }, [privatePolicyFormRef?.current?.clientHeight]);

  useEffect(() => {
    if (needHelpModalOpen) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_VIEWED);

      useFormMethods.reset();
    }
  }, [needHelpModalOpen]);

  const calculatedHeight = height === "fit-content" ? height : height < 650 ? "650px" : `${height}px`;

  return (
    <>
      <SafeLeaseButton
        sx={{
          color: highlight ? "#ffffff" : "#062031",
          fontWeight: 500,
          backgroundColor: highlight ? "#062031" : "transparent",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: highlight ? "#062031" : "#b3cefd",
            boxShadow: highlight ? "0 0 5px 0 rgba(0, 0, 0, 0.1)" : "none",
          },
        }}
        onClick={handleNeedHelpButtonClick}
      >
        Need help?
      </SafeLeaseButton>
      <Modal open={needHelpModalOpen} onClose={handleClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ ...privatePolicyFormStyles, height: calculatedHeight, overflow: "auto" }}
          spacing={{ xs: 0, sm: 1 }}
        >
          <FormProvider {...useFormMethods}>
            <form style={{ width: "100%", height: "100%", borderRadius: 3 }}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent={!success ? "space-between" : "flex-end"}
                alignItems="center"
                paddingBottom={2}
              >
                {!success && <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Support ticket</Typography>}
                <IconButton sx={{ color: "#062031" }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              {success ? (
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" height="100%">
                  <CheckCircleIcon sx={{ fontSize: 36, color: "success.main" }} />
                  <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Thank you for submitting your support request!</Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: (theme: Theme) => theme.palette.grey.A200,
                      textWrap: "balance",
                    }}
                    textAlign="center"
                  >
                    Your request has been received and assigned to our team. Please note that you have not been opted out and are still
                    required to submit a private policy.
                  </Typography>
                </Stack>
              ) : (
                <Stack spacing={2}>
                  <Stack direction="column">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Typography sx={{ fontSize: 14, fontWeight: 500, padding: 0 }} component="label">
                            Phone number
                            <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                              *
                            </Typography>
                          </Typography>
                          <PhoneInput
                            placeholder="Ex: +1 123-456-7890"
                            defaultCountry="US"
                            international
                            inputComponent={StyledSafeLeaseFormTextField}
                            error={!!formState.errors.phone?.message}
                            helperText={formState.errors.phone?.message}
                            fullWidth
                            {...field}
                          />
                        </>
                      )}
                    />
                  </Stack>
                  <StyledSafeLeaseFormTextField
                    name="facilityName"
                    helperText={formState.errors.facilityName?.message}
                    error={!!formState.errors.facilityName?.message}
                    label={
                      <>
                        Facility name
                        <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                          *
                        </Typography>
                      </>
                    }
                    placeholder="Ex: SafeLease - 800 Brazos Street, Austin TX 78745"
                  />
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Stack direction="column" sx={{ width: { xs: "100%", sm: "50%" } }}>
                      <StyledSafeLeaseFormTextField
                        name="unit"
                        helperText={formState.errors.unit?.message}
                        error={!!formState.errors.unit?.message}
                        label={
                          <>
                            Unit
                            <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                              *
                            </Typography>
                          </>
                        }
                        placeholder="Ex: 1A"
                      />
                    </Stack>
                    <Stack direction="column" sx={{ width: { xs: "100%", sm: "50%" } }}>
                      <Typography component="label" sx={{ fontSize: 14, fontWeight: 500, mb: "7px" }}>
                        Move-in date
                      </Typography>
                      <DatePicker
                        value={watch("moveInDate") ? dayjs(watch("moveInDate")) : null}
                        onChange={(date: Dayjs | null) => setValue("moveInDate", date ? date.toDate() : null)}
                        slotProps={{
                          textField: {
                            placeholder: "Ex: 01/01/2025",
                            fullWidth: true,
                            error: !!formState.errors.moveInDate?.message,
                            helperText: formState.errors.moveInDate?.message,
                            InputProps: {
                              sx: {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #ebeff7",
                                  borderRadius: "8px",
                                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #ebeff7",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #277afb",
                                },
                                "& .MuiInputBase-input": { padding: 1 },
                              },
                            },
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <StyledSafeLeaseFormTextField
                    name="description"
                    helperText={formState.errors.description?.message}
                    error={!!formState.errors.description?.message}
                    multiline
                    rows={7}
                    label={
                      <>
                        How can we help you?
                        <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                          *
                        </Typography>
                      </>
                    }
                    placeholder="Ex: I don't know my unit number"
                  />
                  <SafeLeaseButton
                    onClick={onSubmit}
                    variant="contained"
                    disabled={!formState.isValid || loading}
                    sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
                  >
                    Submit
                  </SafeLeaseButton>
                </Stack>
              )}
            </form>
          </FormProvider>
        </Stack>
      </Modal>
    </>
  );
}

export { NeedHelpComponent };
