import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import { Button, Chip, CircularProgress, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { penniesToDollars } from "../utils/formatters";
import { grey } from "@mui/material/colors";
import { AlertBanner } from "../shared/AlertBanner.tsx";
import useMixpanel from "../hooks/useMixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { getSafeleasePlanName } from "../utils/helpers/privatePolicy.ts";

interface PrivatePolicyProtectionPlanSelectionProps {
  privatePolicy: SafeleasePrivatePolicy;
  onEnrollSuccessful?: () => Promise<void>;
  referrer: string;
}

export function PrivatePolicyProtectionPlanSelection({
  privatePolicy,
  onEnrollSuccessful,
  referrer,
}: PrivatePolicyProtectionPlanSelectionProps) {
  const queryClient = useQueryClient();
  const { mixpanelTrack } = useMixpanel();

  const [isEnrollErrorPlan, setIsEnrollErrorPlan] = useState<{ premium: number; coverage: number } | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<{ premium: number; coverage: number } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const privatePolicyProtectionPlansQuery = useQuery({
    queryKey: ["get-protection-plans", `${privatePolicy.id}`],
    queryFn: async () => {
      if (!privatePolicy.id) return null;

      const result = await ClaimsApi.getPrivatePolicyProtectionPlans(`${privatePolicy.id}`);

      if (!result.data) return null;

      setSelectedPlan(result.data.plans[0]);

      const plans = result.data.plans;

      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_VIEWED, {
        privatePolicyId: privatePolicy.id,
        referrer,
        plans,
      });

      return plans;
    },
    enabled: !!privatePolicy.id,
  });

  useEffect(() => {
    if (selectedPlan) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_PLAN_SELECTED, {
        privatePolicyId: privatePolicy.id,
        referrer,
        plans: privatePolicyProtectionPlansQuery.data,
        coverage: selectedPlan.coverage,
        premium: selectedPlan.premium,
      });
    }
  }, [selectedPlan]);

  const enrollSafeleasePlan = async () => {
    if (!selectedPlan?.coverage || !selectedPlan?.premium) return;

    if (
      !window.confirm(
        "By enrolling in this plan, your active private plan(s) for this unit will be archived. Are you sure you want to proceed?",
      )
    )
      return;

    const plans = privatePolicyProtectionPlansQuery.data ?? [];

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_PLAN_SUBMITTED, {
      privatePolicyId: privatePolicy.id,
      referrer,
      plans,
      coverage: selectedPlan.coverage,
      premium: selectedPlan.premium,
      index: plans.findIndex((plan: any) => plan.coverage === selectedPlan.coverage && plan.premium === selectedPlan.premium),
    });

    try {
      setIsLoading(true);
      await ClaimsApi.enrollUnitInProtectionPlan({
        locationId: privatePolicy.locationId,
        unitName: privatePolicy.unitName,
        coverage: selectedPlan.coverage,
        premium: selectedPlan.premium,
        referrer,
      });
      await queryClient.refetchQueries({ queryKey: ["private-policies"] });

      onEnrollSuccessful && (await onEnrollSuccessful());
    } catch (e) {
      console.error(e);
      setIsEnrollErrorPlan(selectedPlan);
    } finally {
      setIsLoading(false);
    }
  };

  if (privatePolicyProtectionPlansQuery.isLoading || !privatePolicyProtectionPlansQuery.data) return <CircularProgress />;
  if (privatePolicyProtectionPlansQuery.isError) return <Typography>Error loading protection plans</Typography>;
  if (!privatePolicyProtectionPlansQuery.data.length) return <Typography>No protection plans available</Typography>;

  return (
    <Stack spacing={2} width="100%">
      {privatePolicyProtectionPlansQuery.data.map((plan: any) => {
        const isSelected = plan.coverage === selectedPlan?.coverage && plan.premium === selectedPlan?.premium;
        const labelKey = getSafeleasePlanName(privatePolicyProtectionPlansQuery.data ?? [], plan);
        const { label, ...labelSx }: LabelConfig = labelConfigs[labelKey];
        return (
          <Stack
            key={`${plan.coverage}-${plan.premium}`}
            direction="column"
            paddingY={1}
            paddingX={2}
            sx={{
              border: "1px solid #1E2A38",
              borderRadius: 1,
              backgroundColor: isSelected ? "#031E300D" : null,
              cursor: "pointer",
            }}
            onClick={() => setSelectedPlan({ premium: plan.premium, coverage: plan.coverage })}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                disabled={false}
                control={
                  <Radio
                    sx={{
                      color: "#1E2A38",
                      "&.Mui-checked": {
                        color: "#1E2A38",
                      },
                    }}
                    checked={isSelected}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<Typography sx={{ fontWeight: 700 }}>{penniesToDollars(plan.premium, 0)} / month</Typography>}
              />
              <Chip
                size="small"
                label={label}
                sx={{
                  ...labelSx,
                  borderRadius: 1,
                  "& .MuiChip-label": {
                    fontSize: 13,
                    fontWeight: 400,
                  },
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">This plan offers a total of {penniesToDollars(plan.coverage, 0)} in coverage</Typography>
            </Stack>
          </Stack>
        );
      })}
      <Button onClick={enrollSafeleasePlan} variant="contained" fullWidth sx={{ mt: 2, backgroundColor: "#1E2A38" }} disabled={isLoading}>
        Enroll in selected plan
      </Button>
      {!!isEnrollErrorPlan && (
        <AlertBanner
          type="error"
          message={`Error enrolling in ${penniesToDollars(isEnrollErrorPlan.coverage, 0)} coverage for ${penniesToDollars(isEnrollErrorPlan.premium, 0)} / month plan.`}
        />
      )}
    </Stack>
  );
}

// Status config visuals
type LabelConfig = {
  label: string;
  color: string;
  bgcolor: string;
};

const labelConfigs: {
  [key: string]: LabelConfig;
} = {
  Basic: {
    label: "Basic",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[300],
  },
  Standard: {
    label: "Standard",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[300],
  },
  Preferred: {
    label: "Preferred",
    color: "white",
    bgcolor: "rgb(0, 123, 255)",
  },
  Premium: {
    label: "Premium",
    color: "white",
    bgcolor: "rgb(24, 65, 172)",
  },
};
