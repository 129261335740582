import { Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";

interface AlertBannerProps {
  type: AlertBannerType;
  message: any;
  onClick?: () => void;
  sx?: any;
}

function AlertBanner({ type, message, onClick = () => {}, sx = {} }: AlertBannerProps) {
  const { borderColor, backgroundColor, iconColor } = typeConfigs[type];
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      marginY={1}
      padding={1}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: 1,
        backgroundColor,
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <ErrorOutlineIcon sx={{ color: iconColor }} />
        <Typography variant="body2" sx={{ color: "#031e30" }}>
          {message}
        </Typography>
      </Stack>
    </Stack>
  );
}

export { AlertBanner };

export type AlertBannerType = "error" | "warning" | "info" | "success";

// Status config visuals
type TypeConfig = {
  borderColor: string;
  backgroundColor: string;
  iconColor: string;
};

const typeConfigs: {
  [key: string]: TypeConfig;
} = {
  error: {
    borderColor: "#e9645f",
    backgroundColor: "#fcefef",
    iconColor: "#e9645f",
  },
  warning: {
    borderColor: "#ffc300",
    backgroundColor: "#fff8e5",
    iconColor: "#ffc300",
  },
  info: {
    borderColor: "#ebeff7",
    backgroundColor: "#f5f7fb",
    iconColor: "#031e30",
  },
  success: {
    borderColor: "#37b34a",
    backgroundColor: "#f0f9f1",
    iconColor: "#37b34a",
  },
};
