import React from "react";
import { SingleFileUploadFormComponent } from "./SingleFileUploadFormComponent";
import { enqueueSnackbar } from "notistack";
import { useClaimCreationStore } from "./useClaimCreationStore";
import { putFileToS3PresignedUrl } from "../../../api";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { useQuery } from "@tanstack/react-query";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";

type BillingLedgerUploadProps = {};

function BillingLedgerUpload({}: BillingLedgerUploadProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);

  // this pulls the billing ledger docs instead of the whole claim
  const billingLederDocsQuery = useQuery({
    queryKey: ["getBillingLedgerDocs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getBillingLedgerDoc(claimId);
      return response.data;
    },
    gcTime: 0,
    refetchOnWindowFocus: false, // adding this to fix bugs where some mobile uploads to the file upload component would cause a re-render of the component causing the file upload component to be reset
  });

  // creating the billing ledger docs existingFiles prop to pass down.
  const existingBillingLedgerFiles = billingLederDocsQuery.data?.filter((bld) => bld.attachment).map((bld) => bld.attachment);

  const continueToNextStep = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, files: File[]) => {
    if (creationStep === null || !claimId) {
      return;
    }
    setLoading(true);
    try {
      for (const billingLedgerFile of files) {
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(billingLedgerFile.name, billingLedgerFile.type);
        const { attachment, presignedUrl } = response.data;
        await putFileToS3PresignedUrl(billingLedgerFile, presignedUrl);
        await ClaimsApi.createBillingLedgerDoc({
          claimId,
          attachmentId: attachment.id,
        });
      }
      await ClaimsApi.updateClaim(claimId as string, {
        creationStep: creationStep + 1,
      });
      setLoading(false);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar("Error uploading billing ledger, please try again.", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  if (billingLederDocsQuery.isLoading || billingLederDocsQuery.isFetching) {
    return <LinearProgress />;
  }

  return (
    <SingleFileUploadFormComponent
      title="Please upload your"
      strongTitle="payment history"
      subTitle="Your payment history (Billing Ledger) outlines the specific billing details for your unit."
      onContinue={continueToNextStep}
      helperText="The proof of payment history is a Billing Ledger. If you have further questions, please contact your storage facility staff for assistance."
      existingFiles={existingBillingLedgerFiles as Attachment_ClaimsDbModel[]}
    />
  );
}

export { BillingLedgerUpload };
