import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Paper, Stack, Typography } from "@mui/material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import CloseIcon from "@mui/icons-material/Close";
import { PrivatePolicyProtectionPlanSelection } from "./PrivatePolicyProtectionPlanSelection.tsx";
import { SuccessMessage } from "../shared/SuccessMessage.tsx";

interface PrivatePolicyProtectionPlanSelectionModalProps {
  handleClose: () => void;
  privatePolicy: SafeleasePrivatePolicy;
}

export function PrivatePolicyProtectionPlanSelectionModal({ handleClose, privatePolicy }: PrivatePolicyProtectionPlanSelectionModalProps) {
  const [isEnrollSuccess, setIsEnrollSuccess] = useState(false);

  const onEnrollSuccessful = async () => {
    setIsEnrollSuccess(true);
  };

  return (
    <Modal onClose={handleClose} open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}>
      <Paper sx={{ minWidth: "150px", minHeight: "100px", maxWidth: { xs: "320px", sm: "600px", md: "800px" }, padding: 2 }}>
        {isEnrollSuccess ? (
          <>
            <SuccessMessage
              title={`Congratulations, your belongings in Unit ${privatePolicy.unitName} are now protected!`}
              subtitle={`If you have any questions about your protection, contact your facility.`}
            />
            <Button onClick={handleClose} fullWidth variant="contained" sx={{ mt: 2, backgroundColor: "#031E30" }}>
              I'm done
            </Button>
          </>
        ) : (
          <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="h6" fontWeight={600}>
                Select your supplemental insurance
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: "#1E2A38" }} />
              </IconButton>
            </Stack>
            <Stack direction={"column"} alignItems={"center"}>
              <Typography variant="caption" marginY={2}>
                Upon selecting a plan, you will be auto enrolled in a supplemental plan offered by your facility.
              </Typography>
              <PrivatePolicyProtectionPlanSelection
                privatePolicy={privatePolicy}
                onEnrollSuccessful={onEnrollSuccessful}
                referrer="private-policy-modal"
              />
              <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 2 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Email support@safelease.com</Typography>
              </Box>
            </Stack>
          </>
        )}
      </Paper>
    </Modal>
  );
}
