import { forwardRef } from "react";
import { SafeLeaseFormTextField } from "@safelease/components";

export const StyledSafeLeaseFormTextField = forwardRef((props: any, ref) => {
  return (
    <SafeLeaseFormTextField
      inputRef={ref}
      labelProps={{
        sx: {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      sx={{ "& .MuiInput-input": { padding: 0 }, borderRadius: "8px", boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)" }}
      {...props}
    />
  );
});
