import { SafeLeaseButton } from "@safelease/components";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import "react-phone-number-input/style.css";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { signUp, signIn } from "aws-amplify/auth";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { AuthServiceApi } from "../utils/apiInstances/AuthServiceApiInstance";
import { useForgotPasswordPageStore, ForgotPasswordStages } from "../AuthenticationPage/ForgotPasswordPages/useForgotPasswordPageStore";
import { KeyboardArrowLeft, DescriptionOutlined, RemoveModeratorOutlined } from "@mui/icons-material";
import { LandingSection } from "./LandingSection";
import { Box, Theme, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { StyledSafeLeaseFormTextField } from "../shared/StyledSafeLeaseFormTextField";

export type PasswordlessSignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const validationSchema = z.object({
  email: z.string().trim().min(1, "Email is required.").email(), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
  firstName: z.string().trim().min(1, "First name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
  lastName: z.string().trim().min(1, "Last name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
});

type PasswordlessSignUpFormProps = {};

function PasswordlessSignUpForm({}: PasswordlessSignUpFormProps) {
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);
  const setNeedsPasswordReset = useAuthenticationPageStore((state) => state.setNeedsPasswordReset);
  const setForgotPasswordStage = useForgotPasswordPageStore((state) => state.setForgotPasswordStage);

  const navigate = useNavigate();

  const [signUpStage, setSignUpStage] = useState<"landing" | "claim-submission" | "private-policy">("landing");
  const [claimSubmissionLoading, setClaimSubmissionLoading] = useState<boolean>(false);
  const [optOutLoading, setOptOutLoading] = useState<boolean>(false);
  const [ignoreBackButton, setIgnoreBackButton] = useState<boolean>(false);
  const { email, setEmail } = useAuthenticationPageStore((state) => state);

  const useFormMethods = useForm<PasswordlessSignUpFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: email,
    },
    resolver: zodResolver(validationSchema),
  });
  const { formState, handleSubmit } = useFormMethods;

  const onClaimSubmissionButtonClicked: SubmitHandler<PasswordlessSignUpFormValues> = async (data) => {
    onSignupButtonClicked(data, "/manage-claims");
  };

  const onOptOutButtonClicked: SubmitHandler<PasswordlessSignUpFormValues> = async (data) => {
    onSignupButtonClicked(data, "/private-policy");
  };

  const onSignupButtonClicked = async (data: PasswordlessSignUpFormValues, navigateTo: string) => {
    if (navigateTo === "/manage-claims") {
      setClaimSubmissionLoading(true);
    } else if (navigateTo === "/private-policy") {
      setOptOutLoading(true);
    }
    setEmail(data.email);

    const sanitizedData = {
      ...data,
      email: data.email.trim(), // removing all leading and trailing white spaces from the email
      firstName: data.firstName.trim(), // removing all leading and trailing white spaces from the first name
      lastName: data.lastName.trim(), // removing all leading and trailing white spaces from the last name
    };

    try {
      // Generating a random password for the user to immediately sign in with uuidv4 that passes cognito's password policy
      const password = uuidv4().substring(0, 13).toUpperCase() + uuidv4().substring(0, 13).toLowerCase();
      await signUp({
        username: sanitizedData.email,
        password: password,
        options: {
          userAttributes: {
            email: sanitizedData.email,
            given_name: sanitizedData.firstName,
            family_name: sanitizedData.lastName,
            phone_number: "+10000000000", // phone number is not required for passwordless sign up, but we need to pass a default fake number to cognito
            "custom:needsPasswordReset": "true",
            "custom:textCommsAccepted": "false",
            "custom:isEmailVerified": "false",
          },
        },
      });
      await signIn({ username: sanitizedData.email, password: password });
      navigate(navigateTo);
    } catch (error: any) {
      console.log(error);
      if (error?.name === "UsernameExistsException") {
        const response = await AuthServiceApi.getTenantPasswordResetStatusByEmail(sanitizedData.email);
        if (response.data?.needsPasswordReset === true) {
          enqueueSnackbar("A user with this email already exists. Please set a password.", { variant: "warning" });
          setCurrentFormContextType(FormContentType.FORGOT_PASSWORD);
          setForgotPasswordStage(ForgotPasswordStages.EMAIL_SUBMIT);
          setNeedsPasswordReset(true);
        } else {
          enqueueSnackbar("A user with this email already exists. Please login.", { variant: "warning" });
          setCurrentFormContextType(FormContentType.LOGIN);
          setNeedsPasswordReset(false);
        }
      } else {
        enqueueSnackbar(error?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
      }
    } finally {
      if (navigateTo === "/manage-claims") {
        setClaimSubmissionLoading(false);
      } else if (navigateTo === "/private-policy") {
        setOptOutLoading(false);
      }
    }
  };

  // if the route has a query param of "private-policy", set the signUpStage to "private-policy"
  useEffect(() => {
    if (new URLSearchParams(window.location.search).get("private-policy")) {
      setIgnoreBackButton(true);
      setSignUpStage("private-policy");
    }
  }, [window.location.search]);

  if (signUpStage === "landing") {
    return <LandingSection setSignUpStage={setSignUpStage} />;
  }

  return (
    <FormProvider {...useFormMethods}>
      <form style={{ height: "100%" }}>
        <Stack justifyContent="space-between" height="100%" padding={{ xs: 2, sm: 0 }}>
          <Stack spacing={2}>
            {!ignoreBackButton && (
              <Box paddingBottom={0.5}>
                <SafeLeaseButton
                  startIcon={<KeyboardArrowLeft sx={{ height: 20, width: 20 }} />}
                  onClick={() => setSignUpStage("landing")}
                  sx={{ "&:focus": { outline: "none" }, color: "grey.A200", fontWeight: 500 }}
                  size="small"
                >
                  Back to tenant services
                </SafeLeaseButton>
              </Box>
            )}
            <Stack direction="row" spacing={1} alignItems="center" paddingBottom={1}>
              {signUpStage === "private-policy" ? (
                <RemoveModeratorOutlined sx={{ fontSize: 32, color: "#277afb" }} />
              ) : (
                <DescriptionOutlined sx={{ fontSize: 32, color: "#277afb" }} />
              )}
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
                {signUpStage === "private-policy" ? "Start opt-out" : "Claim submission"}
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              Your details
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 14,
                color: (theme: Theme) => theme.palette.grey.A200,
                paddingBottom: 1,
              }}
            >
              Enter your details as listed on your lease agreement. All fields are required.
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2 }}>
              <StyledSafeLeaseFormTextField
                name="firstName"
                helperText={formState.errors.firstName?.message}
                error={!!formState.errors.firstName?.message}
                label={
                  <>
                    First name
                    <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                      *
                    </Typography>
                  </>
                }
                placeholder="Ex: John"
              />
              <StyledSafeLeaseFormTextField
                name="lastName"
                helperText={formState.errors.lastName?.message}
                error={!!formState.errors.lastName?.message}
                label={
                  <>
                    Last name
                    <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                      *
                    </Typography>
                  </>
                }
                placeholder="Ex: Smith"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} paddingBottom={3}>
              <Box
                flex={1}
                sx={{
                  "& .MuiTypography-caption": {
                    color: (theme: Theme) => (formState.errors.email?.message ? theme.palette.error.main : theme.palette.grey.A200),
                  },
                }}
              >
                <StyledSafeLeaseFormTextField
                  name="email"
                  helperText={formState.errors.email?.message ?? "Use email listed in your lease agreement"}
                  error={!!formState.errors.email?.message}
                  label={
                    <>
                      Email
                      <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                        *
                      </Typography>
                    </>
                  }
                  labelProps={{
                    sx: {
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  }}
                  placeholder="Ex: john.smith@gmail.com"
                />
              </Box>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <SafeLeaseButton
              onClick={() => {
                if (signUpStage === "private-policy") {
                  handleSubmit(onOptOutButtonClicked)();
                } else {
                  handleSubmit(onClaimSubmissionButtonClicked)();
                }
              }}
              variant="contained"
              sx={{ width: "100%", backgroundColor: "#287cfb", color: "#ffffff", fontWeight: 500, borderRadius: "8px" }}
              loading={optOutLoading || claimSubmissionLoading}
              disabled={!formState.isValid}
            >
              Get started
            </SafeLeaseButton>
            <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center" paddingY={ignoreBackButton ? 3 : 0}>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A200, fontSize: 14, fontWeight: 500 }}>
                Have a SafeLease account?
              </Typography>
              <Typography
                id="e2e-login-link-from-signup-form"
                onClick={() => setCurrentFormContextType(FormContentType.LOGIN)}
                sx={{ color: "#277afb", cursor: "pointer", fontSize: 14, fontWeight: 500 }}
              >
                Login here
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { PasswordlessSignUpForm };
