import { NeedHelpComponent } from "./NeedHelpComponent";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import {
  Typography,
  Stack,
  AutocompleteChangeReason,
  TextField,
  Autocomplete,
  LinearProgress,
  Box,
  Theme,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import { useEffect, useState } from "react";
import { PrivatePolicySubmissionStepType, usePrivatePolicyFormStore, MatchedUnit } from "./usePrivatePolicyFormStore";
import { AlertBanner } from "../shared/AlertBanner";
import { SafeLeaseButton } from "@safelease/components";
import { useNavigate } from "react-router-dom";
import useMixpanel from "../hooks/useMixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";

function UnitMatchingComponent() {
  const { mixpanelTrack } = useMixpanel();
  const navigate = useNavigate();
  const { matchedLocation, matchedUnit, setMatchedUnit, setCurrentPrivatePolicySubmissionStep, handleCancel } = usePrivatePolicyFormStore();

  const [foundTenantUnits, setFoundTenantUnits] = useState<MatchedUnit[]>([]);
  const [unitOptions, setUnitOptions] = useState<MatchedUnit[]>([]);
  const [unit, setUnit] = useState<MatchedUnit | null>(null);

  const { isLoading: isLoadingUnitsByLocationAndTenant } = useQuery({
    enabled: !!matchedLocation,
    queryKey: ["unitsByLocationAndTenant", matchedLocation?.locationId],
    queryFn: async () => {
      const response = await ClaimsApi.getSafeleaseUnitsByLocationAndTenant(
        matchedLocation?.locationId ? `${matchedLocation!.locationId}` : "",
      );

      const matchedTenantUnits = (response.data.matchedTenantUnits ?? []).map((unit) => ({ ...unit, matchedBySafelease: true }));
      const locationUnitOptions = (response.data.locationUnitOptions ?? []).map((unit) => ({ ...unit, matchedBySafelease: false }));
      const dedupedUnitOptions = locationUnitOptions.filter((u) => !matchedTenantUnits.find((mtu) => mtu.id === u.id));

      if (matchedTenantUnits.length > 0) {
        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_UNIT_AUTO_MATCH_SUCCESS, {
          location: matchedLocation,
          matchedTenantUnits: matchedTenantUnits,
          otherUnitOptions: dedupedUnitOptions,
        });
      } else {
        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_UNIT_AUTO_MATCH_FAILED, {
          location: matchedLocation,
          otherUnitOptions: dedupedUnitOptions,
        });
      }

      setFoundTenantUnits(matchedTenantUnits);
      setUnitOptions(dedupedUnitOptions);
      return response.data ?? null;
    },
  });

  // Remember the matched location when navigating back from the next step
  useEffect(() => {
    if (matchedUnit) {
      setUnit(matchedUnit);
    }

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_UNIT_STEP_VIEWED, {
      location: matchedLocation,
      unit: matchedUnit,
    });
  }, []);

  const onUnitClick = async (selectedUnit: MatchedUnit | null) => {
    setUnit(selectedUnit);
  };

  const onContinueButtonClick = () => {
    if (!unit) return;

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_UNIT_STEP_SUBMITTED, {
      location: matchedLocation,
      unit,
    });

    setMatchedUnit(unit);
    setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.SAFELEASE_SUPPLEMENTAL_COVERAGE);
  };

  const onCancelButtonClick = () => {
    handleCancel(mixpanelTrack, navigate);
  };

  if (isLoadingUnitsByLocationAndTenant) return <LinearProgress />;

  if (!matchedLocation)
    return (
      <AlertBanner
        type="error"
        message="There is an error searching for your units. Please select a facility first."
        onClick={() => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_LOCATION)}
      />
    );

  const isNoFoundTenantUnits = foundTenantUnits.length === 0;
  const isFoundTenantUnitSelected = !!unit && !!foundTenantUnits.find((unitData) => unitData.id === unit.id);

  return (
    <Stack spacing={3} width="100%">
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <WarehouseOutlinedIcon sx={{ fontSize: 32, color: "#277afb" }} />
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Select your unit</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <NeedHelpComponent />
        </Stack>
      </Stack>
      <Stack direction="row" paddingBottom={1}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            color: (theme: Theme) => theme.palette.grey.A200,
          }}
        >
          If you don’t have an assigned unit yet, please come back once your facility assigns you a unit. For multiple units, you will need
          to opt-out of each unit independently.
        </Typography>
      </Stack>
      {foundTenantUnits.length > 0 && (
        <Stack direction="row">
          <Stack direction="column" width="100%" spacing={1.5} sx={{ overflowY: "auto", maxHeight: 210 }}>
            {foundTenantUnits.map((unitData) => (
              <Box
                key={unitData.id}
                sx={{
                  width: "99%",
                  border: "1px solid #c4c4c4",
                  borderRadius: "8px",
                  paddingLeft: 2,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: "#031E300D",
                  },
                }}
              >
                <FormControlLabel
                  sx={{ width: "99%" }}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#277afb",
                        },
                      }}
                      value={unit?.id === unitData.id}
                      checked={unit?.id === unitData.id}
                      onClick={(e) => {
                        // If clicking on an already checked radio, uncheck it
                        if (unit?.id === unitData.id) {
                          setUnit(null);
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUnit(unitData);
                        } else {
                          setUnit(null);
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ fontSize: 20, fontWeight: 500 }}>{unitData.name}</Typography>}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      )}
      <Stack direction="row">
        <Box sx={{ width: "100%" }}>
          {!isNoFoundTenantUnits && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                mb: 1,
              }}
            >
              Don't see your unit number?
            </Typography>
          )}
          <Typography component="label" sx={{ fontSize: 14, fontWeight: 500, ...(!isNoFoundTenantUnits ? { color: "grey.A200" } : {}) }}>
            Unit number
            {isNoFoundTenantUnits && (
              <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                *
              </Typography>
            )}
          </Typography>
          <Autocomplete
            value={unit}
            options={unitOptions}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.name}
            onChange={(_, value, reason: AutocompleteChangeReason) => onUnitClick(reason === "clear" ? null : value)}
            forcePopupIcon={false}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-root": {
                    color: "black",
                    padding: "1px 12px",
                    fontSize: 15,
                    borderRadius: "8px",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "grey.A200",
                  },
                }}
                helperText="Make sure this matches your lease agreement"
                placeholder="Search units"
                fullWidth
              />
            )}
            disabled={isFoundTenantUnitSelected}
            noOptionsText="No units"
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                ],
              },
            }}
          />
        </Box>
      </Stack>
      <SafeLeaseButton
        onClick={onContinueButtonClick}
        variant="contained"
        disabled={!unit}
        sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
      >
        Continue
      </SafeLeaseButton>
      <SafeLeaseButton
        onClick={onCancelButtonClick}
        variant="contained"
        sx={{
          backgroundColor: "white",
          color: "error.main",
          border: "1px solid",
          borderColor: "error.main",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "error.main",
            color: "white",
            border: "1px solid",
            borderColor: "error.main",
          },
        }}
      >
        Cancel
      </SafeLeaseButton>
    </Stack>
  );
}

export { UnitMatchingComponent };
