import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { CircularProgress, SxProps } from "@mui/material";

const GOOGLE_MAPS_API_KEY = "AIzaSyAW8FSl4u68WQbW57iRTikBYR4FwAXh4zU"; // TODO: figure out a way to whitelist this key to the tenant portal frontend

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

type GooglePlacesAutocompleteProps = {
  value: PlaceType | null;
  setValue: React.Dispatch<React.SetStateAction<PlaceType | null>>;
  label?: string;
  labelSx?: SxProps;
  placeholder?: string;
  helperText?: string;
  textFieldSx?: SxProps; // style customization for the text field
  popupIcon?: React.ReactNode;
  openText?: string;
  closeText?: string;
  skipGoogleLoadScript?: boolean;
};

function GooglePlacesAutocomplete({
  value,
  setValue,
  label,
  labelSx,
  placeholder,
  helperText,
  textFieldSx = {},
  popupIcon,
  openText,
  closeText,
  skipGoogleLoadScript = false,
}: GooglePlacesAutocompleteProps) {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps") && !skipGoogleLoadScript) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google?.maps?.places?.AutocompleteService) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  if (!loaded.current) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {label && (
        <Typography component="label" sx={{ fontSize: 14, fontWeight: 500, ...labelSx }}>
          {label}
          <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
            *
          </Typography>
        </Typography>
      )}
      <Autocomplete
        getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No results"
        onChange={(_: any, newValue: PlaceType | null, reason: AutocompleteChangeReason) => {
          if (reason === "clear") {
            setValue(null);
          } else {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField sx={textFieldSx} helperText={helperText} {...params} placeholder={placeholder} fullWidth />}
        popupIcon={popupIcon || undefined}
        forcePopupIcon={popupIcon ? true : undefined}
        openText={openText || undefined}
        closeText={closeText || undefined}
        sx={
          popupIcon
            ? {
                "& button.MuiAutocomplete-popupIndicatorOpen": {
                  transform: "none",
                },
              }
            : undefined
        }
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                  {parts.map((part, index) => (
                    <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}

export { GooglePlacesAutocomplete };
