import { Grid, IconButton, Stack, Typography, SxProps, Theme } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";

type FilePreviewsProps = {
  files: (File | Attachment_ClaimsDbModel)[];
  deleteFile?: (file: File | Attachment_ClaimsDbModel) => void;
  sx?: SxProps<Theme>;
};

function FilePreviews({ files, deleteFile, sx }: FilePreviewsProps) {
  return (
    <Stack direction="column" spacing={0.5} sx={sx}>
      {files.map((file) => {
        const key = file instanceof File ? file.name + file.lastModified : file.id;
        const name = file instanceof File ? file.name : file.fileName;
        const size = file instanceof File ? file.size : file.fileSize;

        return (
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            key={key}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <DescriptionOutlinedIcon sx={{ color: "grey", height: "32px", width: "32px" }} />
              <Stack direction="column">
                <Typography sx={{ fontWeight: "600", fontSize: ".9rem" }}>{name}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: ".7rem", color: "grey" }}>
                  {size > 1024 * 1024 ? `${(size / 1024 / 1024).toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`}
                </Typography>
              </Stack>
            </Stack>
            {deleteFile && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    deleteFile(file);
                  }}
                >
                  <DeleteOutlinedIcon sx={{ color: "grey.A200" }} />
                </IconButton>
              </Grid>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

export { FilePreviews };
