import { Box, Stack, Typography } from "@mui/material";
import topLeftLoginBackgroundImage from "../assets/topLeftLoginBackgroundImage.png";
import bottomRightBackgroundImage from "../assets/bottomRightBackgroundImage.png";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { LoginForm } from "./LoginForm";
import { ForgotPasswordPages } from "./ForgotPasswordPages";
import { PasswordlessSignUpForm } from "./PasswordlessSignUpForm";

/**
 * This component is the main component that renders when there is no current authenticated user
 * It is the parent component to all three sets of forms that can be rendered
 * The three different states are SignUpForm, LoginForm, and ForgotPasswordPages
 */
type AuthenticationPageProps = {};

function AuthenticationPage({}: AuthenticationPageProps) {
  const currentFormContentType = useAuthenticationPageStore((state) => state.currentFormContentType);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: { xs: "#152744", sm: "#152744" },
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img style={{ top: 0, left: 0, position: "absolute" }} src={topLeftLoginBackgroundImage} />
        <img style={{ bottom: 0, right: 0, position: "absolute" }} src={bottomRightBackgroundImage} />
      </Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          width: { xs: "100%", sm: "95%" },
          maxWidth: 900,
          minWidth: 300,
          height: { xs: "100%", sm: 543 },
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: { xs: 0, sm: 3 },
          padding: { xs: 0, sm: 3 },
          zIndex: 4,
        }}
        spacing={{ xs: 0, sm: 3 }}
      >
        <Box sx={{ width: "100%", height: "100%", borderRadius: 3, padding: { xs: 0, sm: 2 } }}>
          {currentFormContentType === FormContentType.SIGN_UP && <PasswordlessSignUpForm />}
          {currentFormContentType === FormContentType.LOGIN && <LoginForm />}
          {currentFormContentType === FormContentType.FORGOT_PASSWORD && <ForgotPasswordPages />}
        </Box>
      </Stack>
      <Stack direction="row" marginTop={5} marginBottom={2}>
        <Typography sx={{ fontSize: 12, color: "rgba(255, 255, 255, 1)", textWrap: "balance", textAlign: "center" }}>
          By continuing, you agree to the SafeLease{" "}
          <u>
            <a style={{ color: "rgba(255, 255, 255, 1)" }} href="https://info.safelease.com/terms-of-service" target="_blank">
              Terms of Service
            </a>
          </u>{" "}
          and{" "}
          <u>
            <a style={{ color: "rgba(255, 255, 255, 1)" }} href="https://info.safelease.com/safelease-privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </u>
          .
        </Typography>
      </Stack>
    </Box>
  );
}

export { AuthenticationPage };
