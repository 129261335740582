import { Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import { FormContentType, useAuthenticationPageStore } from "../useAuthenticationPageStore";
import safelease_logo from "../../assets/safelease_logo.svg";

type AllDoneContentProps = {};

function AllDoneContent({}: AllDoneContentProps) {
  const clear = useForgotPasswordPageStore((state) => state.clear);
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);

  return (
    <Stack height={"100%"} justifyContent={{ sm: "space-between" }} spacing={{ xs: 3, sm: 0 }} padding={{ xs: 2, sm: 0 }}>
      <Stack
        spacing={0.5}
        sx={{
          alignItems: { xs: "center" },
          textAlign: "center",
        }}
      >
        <img src={safelease_logo} style={{ width: 100 }} />
        <Typography sx={{ fontSize: 23, fontWeight: 500 }}>Success!</Typography>
        <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>
          Your password has been reset so now you can sign in to your account
        </Typography>
      </Stack>
      <SafeLeaseButton
        onClick={() => {
          clear();
          setCurrentFormContextType(FormContentType.LOGIN);
        }}
        variant="contained"
        sx={{ width: "100%", backgroundColor: "#287cfb", color: "#ffffff", fontWeight: 500 }}
      >
        Sign In
      </SafeLeaseButton>
    </Stack>
  );
}

export default AllDoneContent;
