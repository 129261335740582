import { SafeLeaseCard } from "@safelease/components";
import { Box, Grid, Stack, Theme, Typography } from "@mui/material";
import { DescriptionOutlined, RemoveModeratorOutlined } from "@mui/icons-material";
import safeleaseLogo from "../assets/safelease_logoicon_rgb_blueonnavyblue.svg";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";

type LandingSectionProps = {
  setSignUpStage: (signUpStage: "landing" | "claim-submission" | "private-policy") => void;
};

function LandingSection({ setSignUpStage }: LandingSectionProps) {
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);

  const claimsClicked = () => {
    setSignUpStage("claim-submission");
  };

  const privatePolicyClicked = () => {
    setSignUpStage("private-policy");
  };

  const userActionCardStyles = {
    height: 190,
    display: "flex",
    boxShadow: 0,
    borderRadius: "8px",
  };

  return (
    <Stack justifyContent="space-between" height="100%" padding={{ xs: 2, sm: 4 }}>
      <Stack spacing={1} alignItems="center">
        <Box sx={{ width: 45, height: 45 }}>
          <img src={safeleaseLogo} alt="SafeLease Logo" />
        </Box>
        <Typography sx={{ fontSize: 24, fontWeight: 500, textAlign: "center", textWrap: "balance" }}>
          Welcome to SafeLease's tenant services
        </Typography>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12} sx={{ paddingRight: { xs: 2, sm: 0 } }}>
            <SafeLeaseCard clickable sx={userActionCardStyles} onClick={privatePolicyClicked}>
              <Stack flex={1} justifyContent="center" alignItems="center" spacing={2}>
                <RemoveModeratorOutlined sx={{ fontSize: 32, color: "#021e30" }} />
                <Typography sx={{ fontSize: 18, fontWeight: 500 }} textAlign="center">
                  Opt-out of SafeLease
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    color: (theme: Theme) => theme.palette.grey.A200,
                    textWrap: "balance",
                  }}
                  textAlign="center"
                >
                  Do you have a private policy that provides coverage for your personal property in storage?
                </Typography>
              </Stack>
            </SafeLeaseCard>
          </Grid>
          <Grid item sm={6} xs={12} sx={{ paddingRight: { xs: 2, sm: 0 } }}>
            <SafeLeaseCard clickable sx={userActionCardStyles} onClick={claimsClicked}>
              <Stack flex={1} justifyContent="center" alignItems="center" spacing={2}>
                <DescriptionOutlined sx={{ fontSize: 32, color: "#021e30" }} />
                <Typography sx={{ fontSize: 18, fontWeight: 500 }} textAlign="center">
                  File a claim
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    color: (theme: Theme) => theme.palette.grey.A200,
                    textWrap: "balance",
                  }}
                  textAlign="center"
                >
                  Have you experienced damage or loss to your personal property stored in your self storage unit?
                </Typography>
              </Stack>
            </SafeLeaseCard>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={0.5} paddingTop={{ xs: 2, sm: 5 }}>
          <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A200, fontSize: 14, fontWeight: 500 }}>
            Have a SafeLease account?
          </Typography>
          <Typography
            id="e2e-login-link-from-signup-form"
            onClick={() => setCurrentFormContextType(FormContentType.LOGIN)}
            sx={{ color: "#277afb", cursor: "pointer", fontSize: 14, fontWeight: 500 }}
          >
            Login here
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export { LandingSection };
