import { Box, CircularProgress, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { GooglePlacesAutocomplete, PlaceType } from "../shared/GooglePlacesAutoComplete";
import { useState, useEffect, useRef } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@mui/icons-material/Cancel";
import { GetSafeleaseLocationByTenantResponse } from "@safelease/service-utilities";
import * as Sentry from "@sentry/react";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";
import useMixpanel from "../hooks/useMixpanel";
import { useQuery } from "@tanstack/react-query";
import { GoogleMap, useJsApiLoader, MarkerF, Libraries } from "@react-google-maps/api";
import { PrivatePolicySubmissionStepType, usePrivatePolicyFormStore } from "./usePrivatePolicyFormStore";
import { NeedHelpComponent } from "./NeedHelpComponent";
import { useNavigate } from "react-router-dom";

type LocationMatchingComponentProps = {};

const GOOGLE_MAPS_API_KEY = "AIzaSyAW8FSl4u68WQbW57iRTikBYR4FwAXh4zU";
const GOOGLE_MAPS_PLACES_LIBRARY = "places";
const GOOGLE_MAPS_MARKER_LIBRARY = "marker";
const GOOGLE_MAPS_LIBRARIES: Libraries = [GOOGLE_MAPS_PLACES_LIBRARY, GOOGLE_MAPS_MARKER_LIBRARY];

function LocationMatchingComponent({}: LocationMatchingComponentProps) {
  const { mixpanelTrack } = useMixpanel();
  const navigate = useNavigate();
  const {
    matchedLocation,
    setMatchedLocation,
    setMatchedUnit,
    currentPrivatePolicySubmissionStep,
    setCurrentPrivatePolicySubmissionStep,
    setNeedHelpModalOpen,
    handleCancel,
  } = usePrivatePolicyFormStore();

  const [foundLocation, setFoundLocation] = useState<GetSafeleaseLocationByTenantResponse | null>(null);
  const [userProvidedLocation, setUserProvidedLocation] = useState<PlaceType | null>(null);
  const [badAddressGeometry, setBadAddressGeometry] = useState<{ lat: number; lng: number } | null>(null);
  const [isLocationSearchLoading, setIsLocationSearchLoading] = useState<boolean>(false);
  const [matchingError, setMatchingError] = useState<string>();
  const [errorCount, setErrorCount] = useState<number>(0);

  const mapRef = useRef(null);
  const { isLoaded: isLoadedGoogleMaps } = useJsApiLoader({
    id: "google-maps",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const { isLoading: isLoadingMatchedLocationByTenant, error: matchedLocationByTenantError } = useQuery({
    queryKey: ["matchedLocationByTenant"],
    retry: false,
    queryFn: async () => {
      const response = await ClaimsApi.getSafeleaseLocationByTenant();

      if (response.data) {
        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_AUTO_MATCH_SUCCESS, {
          location: response.data,
        });
      }

      setFoundLocation(response.data ?? null);
      return response.data ?? null;
    },
  });

  useEffect(() => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_STEP_VIEWED);
  }, []);

  useEffect(() => {
    if (matchedLocationByTenantError) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_AUTO_MATCH_FAILED);
    }
  }, [matchedLocationByTenantError]);

  useEffect(() => {
    onLocationSearch();
  }, [userProvidedLocation]);

  useEffect(() => {
    if (errorCount === 3) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_ERROR_MESSAGE_SHOWN);
    }
  }, [errorCount]);

  const onLocationSearch = async () => {
    setMatchingError(undefined);
    setFoundLocation(null);
    setBadAddressGeometry(null);

    if (!userProvidedLocation) return;

    setIsLocationSearchLoading(true);

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_ATTEMPTED, {
      userProvidedLocation,
    });

    try {
      const response = await ClaimsApi.getSafeleaseLocationForGooglePlaceId(userProvidedLocation.place_id);
      setFoundLocation(response.data);
      setErrorCount(0);

      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_SUCCESS, {
        location: response.data,
      });
    } catch (error: any) {
      setErrorCount(errorCount + 1);
      if (error.response?.data?.type === "LocationNotFound") {
        // Wait for the map to be properly loaded
        if (mapRef.current && window.google && window.google.maps) {
          // Create a dummy div element for the PlacesService
          const placesDiv = document.createElement("div");
          const placesService = new window.google.maps.places.PlacesService(placesDiv);

          placesService.getDetails(
            {
              placeId: userProvidedLocation.place_id,
              fields: ["types", "geometry", "formatted_address"],
            },
            (place, status) => {
              if (status === "OK" && place) {
                const isResidential = place.types?.some((type) =>
                  ["premise", "residential", "house", "housing_development"].includes(type),
                );
                const isStorage = place.types?.some((type) => ["storage"].includes(type));

                if (place.geometry?.location) {
                  setBadAddressGeometry({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                }

                if (isStorage) {
                  mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
                    userProvidedLocation,
                    reason: "isStorageButNotRecognized",
                  });
                  setMatchingError("Sorry, this is not a recognized SafeLease storage facility. Please try again.");
                } else if (isResidential) {
                  mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
                    userProvidedLocation,
                    reason: "isResidential",
                  });
                  setMatchingError("Sorry, this appears to be a residential address. Please enter a storage facility address.");
                } else {
                  mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
                    userProvidedLocation,
                    reason: "isNotStorageFacility",
                  });
                  setMatchingError("Sorry, there are no storage facilities linked to this address. Please try again.");
                }
              } else {
                console.error("Google Place details request failed");
                mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
                  userProvidedLocation,
                  reason: "googlePlaceDetailsRequestFailed",
                });
                setMatchingError("Sorry, this is not a recognized SafeLease storage facility. Please try again.");
              }
            },
          );
        } else {
          console.error("Google Maps API not fully loaded");
          mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
            userProvidedLocation,
            reason: "googleMapsApiNotFullyLoaded",
          });
          setMatchingError("Sorry, this is not a recognized SafeLease storage facility. Please try again.");
        }
      } else if (error.response?.data?.type === "BadRequest") {
        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
          userProvidedLocation,
          reason: "badRequest",
        });
        setMatchingError(
          "There was an error searching for your location. Please ensure you have entered a valid storage facility address.",
        );
      } else {
        Sentry.captureException(error);
        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED, {
          userProvidedLocation,
          reason: "unknownError",
        });
        setMatchingError("There was an error searching for your location. Please try again.");
      }
    } finally {
      setIsLocationSearchLoading(false);
    }
  };

  const onContinueButtonClick = () => {
    if (!foundLocation) return;

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_STEP_SUBMITTED, {
      location: foundLocation,
      isLocationAutoMatched: matchedLocation?.locationId === foundLocation.locationId,
    });

    // Reset the matched unit if the location has changed
    if (matchedLocation?.locationId != foundLocation.locationId) {
      setMatchedUnit(null);
    }

    setMatchedLocation(foundLocation);
    setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_UNIT);
  };

  const onCancelButtonClick = () => {
    handleCancel(mixpanelTrack, navigate);
  };

  const onNeedHelpButtonClick = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_BUTTON_CLICKED, {
      currentPrivatePolicySubmissionStep,
      referrer: "Location Step Alert",
    });
    setNeedHelpModalOpen(true);
  };

  if (isLoadingMatchedLocationByTenant || !isLoadedGoogleMaps) return <LinearProgress />;

  const continentalUSCenter = {
    lat: 39.83,
    lng: -98.58,
  };

  const mapLocationPosition = foundLocation
    ? {
        lat: foundLocation.lat,
        lng: foundLocation.lng,
      }
    : badAddressGeometry && userProvidedLocation
      ? {
          lat: badAddressGeometry.lat,
          lng: badAddressGeometry.lng,
        }
      : continentalUSCenter;

  const mapLocationFullAddress = foundLocation
    ? `${foundLocation?.address}, ${foundLocation?.city}, ${foundLocation?.state}${foundLocation?.postalCode ? ` ${foundLocation?.postalCode}` : ""}`
    : badAddressGeometry && userProvidedLocation
      ? userProvidedLocation.description
      : "";

  const mapZoom = mapLocationFullAddress ? 13 : 4;

  return (
    <Stack spacing={3} width="100%">
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between" paddingBottom={0.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          <MyLocationOutlinedIcon sx={{ fontSize: 32, color: "#277afb" }} />
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Find your facility</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <NeedHelpComponent highlight={!!matchingError} />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Box sx={{ width: "100%" }}>
          <GooglePlacesAutocomplete
            placeholder={foundLocation ? mapLocationFullAddress : "Enter your facility address"}
            textFieldSx={{
              "& .MuiInputBase-root": {
                color: "black",
                padding: "1px 12px",
                fontSize: 15,
                borderRadius: "8px",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              },
              "& .MuiInputBase-input": {
                ...(foundLocation && {
                  "&::placeholder": {
                    opacity: 1,
                  },
                }),
                "&:focus::placeholder": {
                  opacity: 0.42, // MUI's default placeholder opacity
                },
              },
              ...(matchingError && {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "error.main",
                  borderWidth: 2,
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "error.main",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "error.main",
                },
                "& .MuiFormHelperText-root": {
                  color: "error.main",
                },
              }),
            }}
            value={userProvidedLocation}
            setValue={setUserProvidedLocation}
            popupIcon={
              isLocationSearchLoading ? (
                <CircularProgress size={22} sx={{ color: "#277afb" }} />
              ) : foundLocation ? (
                <CheckCircleIcon sx={{ color: "success.main" }} />
              ) : matchingError ? (
                <CancelIcon sx={{ color: "error.main" }} />
              ) : (
                <AccessTimeOutlinedIcon sx={{ color: "#277afb" }} />
              )
            }
            openText={isLocationSearchLoading ? "Loading..." : foundLocation ? "Found" : matchingError ? "Not found" : "Open"}
            closeText={isLocationSearchLoading ? "Loading..." : foundLocation ? "Found" : matchingError ? "Not found" : "Close"}
            label="My facility"
            labelSx={
              matchingError
                ? {
                    color: "error.main",
                  }
                : undefined
            }
            skipGoogleLoadScript={true}
            helperText={foundLocation ? "This is a recognized SafeLease storage facility" : matchingError}
          />
        </Box>
      </Stack>
      {errorCount > 2 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          marginY={1}
          padding={1}
          sx={{
            borderRadius: 1,
            backgroundColor: "#fdead7",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            },
          }}
          onClick={onNeedHelpButtonClick}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <WarningAmberIcon sx={{ color: "#de7101" }} />
            <Typography variant="body2" sx={{ color: "#772917", textWrap: "pretty" }}>
              Having trouble finding your location? Create a support ticket and we will help resolve the issue.
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption" sx={{ color: "#772917", fontWeight: 600, textWrap: "nowrap" }}>
              Get help
            </Typography>
          </Stack>
        </Stack>
      )}
      <Grid item xs={12} paddingBottom={1}>
        <GoogleMap
          id="google-maps"
          mapContainerStyle={{ width: "100%", minHeight: errorCount > 2 ? 160 : 210, borderRadius: "8px" }}
          center={mapLocationPosition}
          zoom={mapZoom}
          options={{ disableDefaultUI: true, zoomControl: true }}
          ref={mapRef}
        >
          {!!mapLocationFullAddress && (
            <MarkerF
              position={mapLocationPosition}
              title={mapLocationFullAddress}
              icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
            />
          )}
        </GoogleMap>
      </Grid>
      <SafeLeaseButton
        onClick={onContinueButtonClick}
        variant="contained"
        disabled={!foundLocation}
        sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
      >
        Continue
      </SafeLeaseButton>
      <SafeLeaseButton
        onClick={onCancelButtonClick}
        variant="contained"
        sx={{
          backgroundColor: "white",
          color: "error.main",
          border: "1px solid",
          borderColor: "error.main",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "error.main",
            color: "white",
            border: "1px solid",
            borderColor: "error.main",
          },
        }}
      >
        Cancel
      </SafeLeaseButton>
    </Stack>
  );
}

export { LocationMatchingComponent };
