import { enqueueSnackbar } from "notistack";
import { useClaimCreationStore } from "./useClaimCreationStore";
import { SingleFileUploadFormComponent } from "./SingleFileUploadFormComponent";
import { putFileToS3PresignedUrl } from "../../../api";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { useQuery } from "@tanstack/react-query";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";

type ProtectionPlanAddendumUploadProps = {};

function ProtectionPlanAddendumUpload({}: ProtectionPlanAddendumUploadProps) {
  const { claimId } = useParams<{ claimId: string }>();

  if (!claimId) return null;
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);

  // pull only the Protection Plan docs instead of the whole claim
  const protectionPlanAddendumnDocsQuery = useQuery({
    queryKey: ["getProtectionPlanAddendumnDocs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getProtectionPlanAddendumDocs(claimId);
      return response.data;
    },
    gcTime: 0,
    refetchOnWindowFocus: false, // adding this to fix bugs where some mobile uploads to the file upload component would cause a re-render of the component causing the file upload component to be reset
  });

  // creating the exisitng protection plan files to pass down as prop.
  const existingProtectionPlanFiles = protectionPlanAddendumnDocsQuery.data?.filter((ppd) => ppd.attachment).map((ppd) => ppd.attachment);

  const continueToNextStep = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, files: File[]) => {
    if (creationStep === null || !claimId) {
      return;
    }
    setLoading(true);
    try {
      for (const protectionPlanAddendumFile of files) {
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(
          protectionPlanAddendumFile.name,
          protectionPlanAddendumFile.type,
        );
        const { presignedUrl, attachment } = response.data;
        await putFileToS3PresignedUrl(protectionPlanAddendumFile, presignedUrl);
        await ClaimsApi.createProtectionPlanAddendumDoc({
          claimId,
          attachmentId: attachment.id,
        });
      }
      await ClaimsApi.updateClaim(claimId as string, {
        creationStep: creationStep + 1,
        status: ClaimStatus.Incomplete,
      });
      setLoading(false);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar("Error uploading coverage plan addendum, please try again.", { variant: "error" });
      setLoading(false);
    }
  };

  if (protectionPlanAddendumnDocsQuery.isLoading || protectionPlanAddendumnDocsQuery.isFetching) {
    return <LinearProgress />;
  }

  return (
    <SingleFileUploadFormComponent
      onContinue={continueToNextStep}
      title="Please upload your"
      strongTitle="Coverage Plan Addendum"
      subTitle="Your Coverage Plan Addendum details the specific terms and conditions that apply to your coverage plan. If you are unable to locate your Coverage Plan Addendum, please contact your storage facility staff for assistance."
      helperText="If you are unable to locate your Coverage Plan Addendum, please contact your storage facility staff for assistance."
      existingFiles={(existingProtectionPlanFiles as Attachment_ClaimsDbModel[]) || []}
    />
  );
}

export { ProtectionPlanAddendumUpload };
