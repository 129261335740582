import { FilePreviews } from "./FilePreviews";
import { alpha, Box, CircularProgress, Typography, IconButton, Stack } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useMemo, useCallback, useState } from "react";
import heic2any from "heic2any";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { useDropzone } from "react-dropzone";
import { enqueueSnackbar } from "notistack";

type FileUploadComponentProps = {
  handleNewFiles: (files: File[]) => void;
  uploadedFiles?: (File | Attachment_ClaimsDbModel)[];
  onDeleteFile?: (file: File | Attachment_ClaimsDbModel) => void;
  accept?: { [key: string]: string[] };
  limit?: number;
  maxSize?: number;
};

function FileUploadComponent({
  handleNewFiles,
  uploadedFiles,
  onDeleteFile,
  accept = {},
  maxSize = 10 * 1024 * 1024,
}: FileUploadComponentProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {};
      reader.readAsArrayBuffer(file);
    });
    onFilesUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop, accept, maxSize });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const [loading, setLoading] = useState(false);

  const convertHeicToJpeg = (file: File): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const blob = new Blob([event.target?.result as ArrayBuffer]);
        heic2any({ blob })
          .then((conversionResult) => {
            resolve(
              new File([conversionResult as Blob], file.name.replace(/\.heic$/i, ".jpeg"), {
                type: "image/jpeg",
                lastModified: Date.now(),
              }),
            );
          })
          .catch((error) => {
            reject(error);
          });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onFilesUpload = async (files: File[]) => {
    if (files.some((file) => file.size > maxSize)) {
      enqueueSnackbar(`One or more files are too large. Please upload files that are less than ${maxSize / 1024 / 1024} MB.`, {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    const newFiles = [];
    for (const file of files) {
      if (file.type === "image/heic") {
        newFiles.push(await convertHeicToJpeg(file));
      } else {
        newFiles.push(file);
      }
    }
    handleNewFiles(newFiles);
    setLoading(false);
  };

  return (
    <Stack sx={{ cursor: "pointer", backgroundColor: "transparent" }}>
      {!uploadedFiles?.length && (
        <Box {...getRootProps(style)}>
          <input {...getInputProps()} />

          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} sx={{ backgroundColor: "transparent" }}>
            <IconButton sx={{ backgroundColor: alpha("#EBEFF7", 0.5), borderRadius: "50%", p: 2 }}>
              <FileUploadOutlinedIcon sx={{ color: "#061e30" }} />
            </IconButton>
            <Stack justifyContent="center" alignItems="flex-start">
              <Typography fontWeight={500}>Click to upload or drag and drop</Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: "grey.A200",
                }}
              >
                Max file size is {maxSize / 1024 / 1024} MB
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
      {uploadedFiles && <FilePreviews deleteFile={onDeleteFile} files={uploadedFiles} sx={{ mt: 1 }} />}
      {loading && <CircularProgress />}
    </Stack>
  );
}

const baseStyle = {
  height: "100%",
  padding: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "white",
  transition: "border .24s ease-in-out",
  border: "1px dashed rgba(3, 30, 48, 0.2)",
  borderRadius: "20px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  backgroundColor: "#EBEFF7",
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: alpha("#E9645F", 0.1),
};

export { FileUploadComponent };
