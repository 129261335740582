import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { ForgotPasswordStages, useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { confirmResetPassword } from "aws-amplify/auth";
import { AuthServiceApi } from "../../utils/apiInstances/AuthServiceApiInstance";

type NewPasswordSubmitContentProps = {};

type NewPasswordFormValues = {
  password: string;
  passwordConfirmation: string;
  verificationCode: string;
};

const validationSchema = z
  .object({
    verificationCode: z.string().min(1, "Verification code is required."),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
    passwordConfirmation: z.string().min(1, "Confirm password is required."),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"], // This specifies which field the error is associated with
  });

function NewPasswordSubmitContent({}: NewPasswordSubmitContentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const setForgotPasswordStage = useForgotPasswordPageStore((state) => state.setForgotPasswordStage);
  const email = useForgotPasswordPageStore((state) => state.email);

  const methods = useForm<NewPasswordFormValues>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
      verificationCode: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onButtonClicked: SubmitHandler<NewPasswordFormValues> = async (data) => {
    setLoading(true);
    try {
      await confirmResetPassword({ confirmationCode: data.verificationCode, username: email, newPassword: data.password });
      await AuthServiceApi.confirmTenantSetPassword(email);
      setForgotPasswordStage(ForgotPasswordStages.ALL_DONE);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: "100%" }} onSubmit={methods.handleSubmit(onButtonClicked)}>
        <Stack height="100%" justifyContent={{ sm: "space-between" }} padding={{ xs: 2, sm: 0 }} spacing={{ xs: 3, sm: 0 }}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
            <Box>
              <SafeLeaseButton
                startIcon={<KeyboardArrowLeft sx={{ height: 20, width: 20 }} />}
                onClick={() => setForgotPasswordStage(ForgotPasswordStages.EMAIL_SUBMIT)}
                sx={{ "&:focus": { outline: "none" }, color: (theme: Theme) => theme.palette.grey.A200, fontWeight: 500 }}
                size="small"
              >
                Back
              </SafeLeaseButton>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: 23, fontWeight: 500 }}>Set new password</Typography>
              <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>
                Enter the code sent to your email and set a new password
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <SafeLeaseFormTextField
                name="verificationCode"
                label="Enter verification code"
                helperText={methods.formState.errors.verificationCode?.message}
                error={!!methods.formState.errors.verificationCode?.message}
              />
              <SafeLeaseFormTextField
                name="password"
                label="Password"
                type="password"
                helperText={methods.formState.errors.password?.message}
                error={!!methods.formState.errors.password?.message}
              />
              <SafeLeaseFormTextField
                name="passwordConfirmation"
                label="Confirm password"
                type="password"
                helperText={methods.formState.errors.passwordConfirmation?.message}
                error={!!methods.formState.errors.passwordConfirmation?.message}
              />
            </Stack>
          </Stack>
          <SafeLeaseButton
            type="submit"
            variant="contained"
            sx={{ width: "100%", backgroundColor: "#287cfb", color: "#ffffff", fontWeight: 500 }}
            loading={loading}
          >
            Reset password
          </SafeLeaseButton>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { NewPasswordSubmitContent };
