import { Box, LinearProgress, Stack, Theme, Typography } from "@mui/material";
import { NeedHelpComponent } from "./NeedHelpComponent";
import { SafeLeaseButton, SafeLeaseChip } from "@safelease/components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { PrivatePolicySubmissionStepType, usePrivatePolicyFormStore } from "./usePrivatePolicyFormStore";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useMixpanel from "../hooks/useMixpanel";
import { useQueryClient } from "@tanstack/react-query";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance";
import _ from "lodash";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";
import { AlertBanner } from "../shared/AlertBanner";
import { penniesToDollars } from "../utils/formatters";
import { getSafeleasePlanName } from "../utils/helpers/privatePolicy";
import { enqueueSnackbar } from "notistack";

function SafeLeaseSupplementalCoverageForm() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mixpanelTrack } = useMixpanel();
  const {
    matchedLocation,
    matchedUnit,
    currentPrivatePolicySubmissionStep,
    setCurrentPrivatePolicySubmissionStep,
    setNeedHelpModalOpen,
    setHidePrivatePolicyFormHeader,
    handleCancel,
  } = usePrivatePolicyFormStore();

  const [isEnrollSuccessful, setIsEnrollSuccessful] = useState<boolean>(false);
  const [isEnrollLoading, setIsEnrollLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<{ premium: number; coverage: number } | null>(null);

  const {
    isLoading: isLoadingProtectionPlans,
    data: protectionPlans,
    isError: isErrorProtectionPlans,
  } = useQuery({
    enabled: !!matchedLocation,
    queryKey: ["get-protection-plans", `${matchedLocation?.locationId}`],
    queryFn: async () => {
      if (!matchedLocation?.locationId) return null;

      const result = await ClaimsApi.getLocationProtectionPlans(`${matchedLocation.locationId}`);

      const plans = _.sortBy(result.data.plans ?? [], "premium");

      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_VIEWED, {
        location: matchedLocation,
        unit: matchedUnit,
        plans,
      });

      if (!plans.length) return null;

      return plans;
    },
  });

  const onEnrollSafeleasePlanButtonClick = async () => {
    if (!selectedPlan?.coverage || !selectedPlan?.premium) return;

    const plans = protectionPlans ?? [];

    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_SUBMITTED, {
      location: matchedLocation,
      unit: matchedUnit,
      plans,
      selectedCoverage: selectedPlan.coverage,
      selectedPremium: selectedPlan.premium,
      selectedPlanIndex: plans.findIndex((plan: any) => plan.coverage === selectedPlan.coverage && plan.premium === selectedPlan.premium),
    });

    try {
      setIsEnrollLoading(true);
      await ClaimsApi.enrollUnitInProtectionPlan({
        locationId: matchedLocation!.locationId,
        unitName: matchedUnit!.name,
        coverage: selectedPlan.coverage,
        premium: selectedPlan.premium,
        referrer: "private-policy-form",
      });
      await queryClient.refetchQueries({ queryKey: ["private-policies"] });

      setHidePrivatePolicyFormHeader(true);
      setIsEnrollSuccessful(true);
    } catch (e) {
      console.error(e);
      enqueueSnackbar("There was an error enrolling in the plan. Please try again.", { variant: "error" });
    } finally {
      setIsEnrollLoading(false);
    }
  };

  const onContinueButtonClick = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_SKIPPED, {
      location: matchedLocation,
      unit: matchedUnit,
      plans: protectionPlans,
    });
    setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.POLICY_DETAILS);
  };

  const onCancelButtonClick = () => {
    handleCancel(mixpanelTrack, navigate);
  };

  const onManageMyPlanButtonClick = () => {
    navigate("/manage-private-policies");
  };

  const togglePlanSelection = (plan: { coverage: number; premium: number }) => {
    if (plan.coverage === selectedPlan?.coverage && plan.premium === selectedPlan?.premium) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_PLAN_UNSELECTED, {
        location: matchedLocation,
        unit: matchedUnit,
        plans: protectionPlans,
        deselectedCoverage: selectedPlan.coverage,
        deselectedPremium: selectedPlan.premium,
      });
      setSelectedPlan(null);
    } else {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_PLAN_SELECTED, {
        location: matchedLocation,
        unit: matchedUnit,
        plans: protectionPlans,
        selectedCoverage: plan.coverage,
        selectedPremium: plan.premium,
      });
      setSelectedPlan(plan);
    }
  };

  const onNeedHelpButtonClick = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_BUTTON_CLICKED, {
      currentPrivatePolicySubmissionStep,
      referrer: "Select Coverage Step Alert",
    });
    setNeedHelpModalOpen(true);
  };

  if (isLoadingProtectionPlans) return <LinearProgress />;

  if (isErrorProtectionPlans)
    return (
      <AlertBanner
        type="error"
        message="There is an error loading protection plans. Please file a support ticket to get help."
        onClick={onNeedHelpButtonClick}
      />
    );

  if (!protectionPlans?.length)
    return (
      <AlertBanner
        type="error"
        message="There are no protection plans available. Please file a support ticket to get help."
        onClick={onNeedHelpButtonClick}
      />
    );

  if (!matchedLocation || !matchedUnit)
    return (
      <AlertBanner
        type="error"
        message="There is an error searching for your protection plans. Please select a facility and unit first."
        onClick={() => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_UNIT)}
      />
    );

  if (isEnrollSuccessful && selectedPlan)
    return (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" height="100%" width="100%">
        <CheckCircleIcon sx={{ fontSize: 36, color: "success.main" }} />
        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
          {matchedUnit.matchedBySafelease
            ? `${matchedUnit.name} has been successfully enrolled in the ${getSafeleasePlanName(protectionPlans, selectedPlan)} plan`
            : `You’ve successfully enrolled in a SafeLease plan`}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            color: (theme: Theme) => theme.palette.grey.A200,
            textWrap: "balance",
          }}
          textAlign="center"
        >
          Your account will be billed monthly via your facility.
        </Typography>
        <Stack direction="row" width="100%" pt={2}>
          <SafeLeaseButton
            onClick={onManageMyPlanButtonClick}
            variant="contained"
            sx={{ backgroundColor: "#277afb", color: "white", width: "100%", borderRadius: "8px" }}
          >
            Exit
          </SafeLeaseButton>
        </Stack>
      </Stack>
    );

  return (
    <Stack spacing={3} width="100%" paddingBottom={{ xs: 2, sm: 0 }}>
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            Great news! Your facility offers{" "}
            {matchedLocation.relationshipProtectionType === "tenantInsurance" ? "insurance plans" : "protection plans"}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <NeedHelpComponent />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            color: (theme: Theme) => theme.palette.grey.A200,
          }}
        >
          Does your policy have a high deductible and low coverage amount? Reduce your financial liability by enrolling in an additional
          insurance coverage plan provided by your facility.
        </Typography>
      </Stack>
      <Stack direction="row" width="100%">
        <Stack direction={{ xs: "column", sm: "row" }} width="100%" spacing={1.5} flexWrap="wrap" useFlexGap>
          {protectionPlans?.map((plan) => {
            const isSelected = plan.coverage === selectedPlan?.coverage && plan.premium === selectedPlan?.premium;
            const planName = getSafeleasePlanName(protectionPlans, plan);

            return (
              <Box
                key={plan.coverage}
                sx={{
                  width: {
                    xs: "99%",
                    sm: protectionPlans.length === 4 ? "calc(50% - 0.75rem)" : `calc(${100 / protectionPlans.length}% - 0.75rem)`,
                    md: `calc(${100 / protectionPlans.length}% - 0.75rem)`,
                  },
                  borderRadius: "8px",
                  padding: 2,
                  "&:hover": {
                    backgroundColor: "#031E300D",
                    cursor: "pointer",
                  },
                  border: isSelected ? "2px solid #277afb" : "1px solid #c4c4c4",
                  ...(isSelected ? { backgroundColor: "#F9FAFB" } : {}),
                }}
                onClick={() => togglePlanSelection(plan)}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height={20}>
                  <Typography sx={{ fontSize: 12, fontWeight: 500 }}>{planName.toUpperCase()}</Typography>
                  {planName === "Preferred" && (
                    <SafeLeaseChip
                      label="Popular"
                      sx={{ bgcolor: "#277afb", color: "white", borderRadius: "8px", "& .MuiChip-label": { py: 2, px: 1 } }}
                    />
                  )}
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0.5} width="100%">
                  <Typography sx={{ fontSize: 34, fontWeight: 500 }}>{penniesToDollars(plan.premium, 0)}</Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 500, pb: 0.75, ...(!isSelected ? { color: "grey.A200" } : {}) }}>
                    / month
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0.5} width="100%">
                  <Typography sx={{ fontSize: 18, fontWeight: 500 }}>${plan.coverage / (1000 * 100)}k</Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 500, pb: 0.25, color: "grey.A200" }}>total coverage</Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" width="100%" mt={1}>
                  <SafeLeaseButton
                    variant="contained"
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      color: "#277afb",
                      border: "1px solid",
                      borderColor: "#277afb",
                      borderRadius: "8px",
                      fontWeight: 500,
                      "&:hover": {
                        backgroundColor: "#277afb",
                        color: "white",
                        border: "1px solid",
                        borderColor: "#277afb",
                      },
                      "&.MuiButton-root": {
                        padding: "2px 16px",
                      },
                    }}
                  >
                    {isSelected ? "Deselect" : "Select"} plan
                  </SafeLeaseButton>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <SafeLeaseButton
        onClick={onEnrollSafeleasePlanButtonClick}
        variant="contained"
        disabled={!selectedPlan || isEnrollLoading}
        sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
      >
        Yes, enroll unit {matchedUnit.name}
      </SafeLeaseButton>
      <SafeLeaseButton
        onClick={onContinueButtonClick}
        variant="contained"
        sx={{
          backgroundColor: "white",
          color: "#277afb",
          border: "1px solid",
          borderColor: "#277afb",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#277afb",
            color: "white",
            border: "1px solid",
            borderColor: "#277afb",
          },
        }}
      >
        No, proceed with opt-out
      </SafeLeaseButton>
      <SafeLeaseButton
        onClick={onCancelButtonClick}
        variant="contained"
        sx={{
          backgroundColor: "white",
          color: "error.main",
          border: "1px solid",
          borderColor: "error.main",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "error.main",
            color: "white",
            border: "1px solid",
            borderColor: "error.main",
          },
        }}
      >
        Cancel
      </SafeLeaseButton>
    </Stack>
  );
}

export { SafeLeaseSupplementalCoverageForm };
