import { useEffect, useRef } from "react";
import { Typography, Box, Stack, LinearProgress } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import useMixpanel from "../hooks/useMixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";
import bottomRightBackgroundImage from "../assets/bottomRightBackgroundImage.png";
import topLeftLoginBackgroundImage from "../assets/topLeftLoginBackgroundImage.png";
import { PrivatePolicySubmissionStepType, usePrivatePolicyFormStore } from "./usePrivatePolicyFormStore.ts";
import { SafeLeaseSupplementalCoverageForm } from "./SafeLeaseSupplementalCoverageForm.tsx";
import { PolicyDetailsForm } from "./PolicyDetailsForm.tsx";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { UnitMatchingComponent } from "./UnitMatchingComponent.tsx";
import { LocationMatchingComponent } from "./LocationMatchingComponent.tsx";

export const privatePolicyFormStyles = {
  width: { xs: "100%", sm: "95%" },
  maxWidth: 900,
  minWidth: 300,
  height: {
    xs: "100%",
    sm: "fit-content",
  },
  minHeight: 200,
  bgcolor: "rgba(255, 255, 255, 1)",
  borderRadius: { xs: 0, sm: 3 },
  padding: { xs: 2, sm: 3 },
  zIndex: 4,
};

type PrivatePolicyFormProps = {};

function PrivatePolicyForm({}: PrivatePolicyFormProps) {
  const {
    currentPrivatePolicySubmissionStep,
    setCurrentPrivatePolicySubmissionStep,
    setPrivatePolicyFormRef,
    clearPrivatePolicyFormStore,
    hidePrivatePolicyFormHeader,
  } = usePrivatePolicyFormStore();
  const { mixpanelTrack } = useMixpanel();
  const formRef = useRef(null);

  useEffect(() => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_VIEWED);

    return () => {
      clearPrivatePolicyFormStore();
    };
  }, []);

  useEffect(() => {
    if (formRef.current) {
      setPrivatePolicyFormRef(formRef);
    }
  }, [formRef.current]);

  const privatePolicyFormStep = {
    [PrivatePolicySubmissionStepType.FIND_LOCATION]: {
      step: 1,
      label: "TENANT VERIFICATION",
      onBackButtonClick: null,
      backButtonText: null,
    },
    [PrivatePolicySubmissionStepType.FIND_UNIT]: {
      step: 1,
      label: "TENANT VERIFICATION",
      onBackButtonClick: () => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_LOCATION),
      backButtonText: "Choose facility",
    },
    [PrivatePolicySubmissionStepType.SAFELEASE_SUPPLEMENTAL_COVERAGE]: {
      step: 2,
      label: "SAFELEASE SUPPLEMENTAL COVERAGE",
      onBackButtonClick: () => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_UNIT),
      backButtonText: "Select unit",
    },
    [PrivatePolicySubmissionStepType.POLICY_DETAILS]: {
      step: 3,
      label: "POLICY DETAILS",
      onBackButtonClick: () => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.SAFELEASE_SUPPLEMENTAL_COVERAGE),
      backButtonText: "Facility plans",
    },
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#152744",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img style={{ top: 0, left: 0, position: "absolute" }} src={topLeftLoginBackgroundImage} />
        <img style={{ bottom: 0, right: 0, position: "absolute" }} src={bottomRightBackgroundImage} />
      </Box>
      <Stack direction={{ xs: "column", sm: "row" }} sx={privatePolicyFormStyles} spacing={{ xs: 0, sm: 3 }} ref={formRef}>
        <Box sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
          {!hidePrivatePolicyFormHeader && privatePolicyFormStep[currentPrivatePolicySubmissionStep].step && (
            <>
              {privatePolicyFormStep[currentPrivatePolicySubmissionStep].onBackButtonClick && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
                  <Box>
                    <SafeLeaseButton
                      startIcon={<KeyboardArrowLeft sx={{ height: 20, width: 20 }} />}
                      onClick={privatePolicyFormStep[currentPrivatePolicySubmissionStep].onBackButtonClick!}
                      sx={{ "&:focus": { outline: "none" }, color: "grey.A200", fontWeight: 500 }}
                      size="small"
                    >
                      {privatePolicyFormStep[currentPrivatePolicySubmissionStep].backButtonText}
                    </SafeLeaseButton>
                  </Box>
                </Stack>
              )}
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" mb={1}>
                <Typography variant="subtitle2">{privatePolicyFormStep[currentPrivatePolicySubmissionStep].label}</Typography>
                <Typography variant="subtitle2">{`${privatePolicyFormStep[currentPrivatePolicySubmissionStep].step}/3`}</Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                value={(privatePolicyFormStep[currentPrivatePolicySubmissionStep].step / 3) * 100}
                sx={{ color: "#287cfb", bgcolor: "#2779fb63", borderRadius: "8px" }}
              />
            </>
          )}
          <Stack pt={3}>
            {currentPrivatePolicySubmissionStep === PrivatePolicySubmissionStepType.FIND_LOCATION && <LocationMatchingComponent />}
            {currentPrivatePolicySubmissionStep === PrivatePolicySubmissionStepType.FIND_UNIT && <UnitMatchingComponent />}
            {currentPrivatePolicySubmissionStep === PrivatePolicySubmissionStepType.SAFELEASE_SUPPLEMENTAL_COVERAGE && (
              <SafeLeaseSupplementalCoverageForm />
            )}
            {currentPrivatePolicySubmissionStep === PrivatePolicySubmissionStepType.POLICY_DETAILS && <PolicyDetailsForm />}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export { PrivatePolicyForm };
