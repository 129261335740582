import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import { Checkbox, FormControlLabel, TextField, Stack, Theme, Popover, IconButton, SvgIcon, CircularProgress } from "@mui/material";
import { Controller, ControllerRenderProps, FormProvider, useForm } from "react-hook-form";
import { forwardRef, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SafeLeaseButton } from "@safelease/components";
import { MIXPANEL_EVENTS } from "../utils/mixpanel.ts";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import { appConfig } from "../config/app.config.ts";
import { validateFileType } from "../utils/FileValidator.ts";
import { PrivatePolicySubmissionStepType, usePrivatePolicyFormStore } from "./usePrivatePolicyFormStore.ts";
import useMixpanel from "../hooks/useMixpanel.ts";
import { useNavigate } from "react-router-dom";
import { StyledSafeLeaseFormTextField } from "../shared/StyledSafeLeaseFormTextField.tsx";
import { NeedHelpComponent } from "./NeedHelpComponent.tsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { AlertBanner } from "../shared/AlertBanner.tsx";
import { FileUploadComponent } from "../shared/FileUploadComponent/FileUploadComponent.tsx";
import { enqueueSnackbar } from "notistack";

export type PolicyDetailsFormValues = {
  insuranceCompany: string;
  policyNumber: string;
  policyDeductible: string;
  expiration: Date | null;
  attachment: File | null;
  policyExpirationRemindersEnabled: boolean;
};

const validationSchema = z.object({
  insuranceCompany: z.string().trim().min(1, "Insurance provider is required."),
  policyNumber: z.string().trim().min(1, "Policy number is required."),
  policyDeductible: z.string().trim().min(1, "Policy deductible is required."),
  expiration: z
    .date()
    .nullable()
    .refine((date) => date !== null, { message: "Expiration date is required." })
    .refine((date) => date === null || date.getTime() > new Date().getTime(), { message: "Expiration date cannot be in the past." }),
  attachment: z
    .instanceof(File)
    .nullable()
    .refine((file) => file !== null, { message: "Proof of insurance is required." }),
  policyExpirationRemindersEnabled: z.boolean().optional(),
});

function PolicyDetailsForm() {
  const useFormMethods = useForm<PolicyDetailsFormValues>({
    defaultValues: {
      insuranceCompany: "",
      policyNumber: "",
      policyDeductible: "",
      expiration: null,
      attachment: null,
      policyExpirationRemindersEnabled: false,
    },
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    shouldFocusError: true,
    reValidateMode: "onChange",
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
    setValue,
  } = useFormMethods;

  const {
    matchedLocation,
    matchedUnit,
    currentPrivatePolicySubmissionStep,
    clearPrivatePolicyFormStore,
    setCurrentPrivatePolicySubmissionStep,
    setHidePrivatePolicyFormHeader,
    setNeedHelpModalOpen,
    handleCancel,
  } = usePrivatePolicyFormStore();
  const { mixpanelTrack } = useMixpanel();
  const navigate = useNavigate();

  const [isPrivatePolicyCreationSuccessful, setIsPrivatePolicyCreationSuccessful] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | null>(null);

  const [proofOfInsuranceAnchorEl, setProofOfInsuranceAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleProofOfInsuranceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProofOfInsuranceAnchorEl(event.currentTarget);
  };
  const handleProofOfInsuranceClose = () => {
    setProofOfInsuranceAnchorEl(null);
  };

  useEffect(() => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_VIEWED, {
      location: matchedLocation,
      unit: matchedUnit,
    });
  }, []);

  const onSubmit = async (data: PolicyDetailsFormValues) => {
    if (!matchedLocation || !matchedUnit) {
      setAlert("Please select a location and unit before submitting.");
      return;
    }

    try {
      setAlert(null);
      setLoading(true);
      const cleanPolicyDeductible = Number(data.policyDeductible.replace(/\D/g, ""));

      const mixpanelProperties = {
        location: matchedLocation,
        unit: matchedUnit,
        ...data,
        locationId: matchedLocation?.locationId,
        policyDeductible: cleanPolicyDeductible,
        unitName: matchedUnit.name,
        matchedSafeleaseUnitIdOnSubmit: matchedUnit.matchedBySafelease ? matchedUnit.id : null,
        policyNumber: undefined, // policyNumber is not tracked in mixpanel to limit PII exposure
      };
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMITTED, mixpanelProperties);

      const privatePolicyData: any = {
        ...data,
        policyDeductible: cleanPolicyDeductible,
        locationId: matchedLocation.locationId,
        unitName: matchedUnit.name,
        matchedSafeleaseUnitIdOnSubmit: matchedUnit.matchedBySafelease ? matchedUnit.id : null,
      };

      if (data.attachment && appConfig.environment !== "demo") {
        // Validate file type before uploading
        let isValidAttachment = await validateFileType(data.attachment);
        if (isValidAttachment) {
          privatePolicyData.attachment_filename = await ClaimsApi.directPrivatePolicyFileUpload({ file: data.attachment });
        }

        privatePolicyData.attachment_desired_filename = data.attachment.name;
        privatePolicyData.attachment_mime_type = data.attachment.type;
        privatePolicyData.isValidFile = isValidAttachment.toString();
      }
      const response = await ClaimsApi.savePrivatePolicy(privatePolicyData);
      if (response.data.errors) {
        for (const errorKey of Object.keys(response.data.errors)) {
          if (errorKey in validationSchema.shape) {
            setError(errorKey as keyof PolicyDetailsFormValues, { type: "manual", message: response.data.errors[errorKey].msg });
          } else {
            setAlert("Error submitting form. Please try again.");
            enqueueSnackbar(response.data.errors[errorKey].msg, { variant: "error" });
          }
        }

        const errorMessages = Object.keys(response.data.errors).map((errorKey) => ({
          field: errorKey,
          message: response.data.errors[errorKey].msg,
        }));

        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMIT_ERROR, {
          ...mixpanelProperties,
          errorMessages,
        });
      } else {
        // Technically more than one private policy can be created, but in practice, there should only be one private policy created with this form
        const createdPrivatePolicyId = response.data.privatePolicyIds[0];

        mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMITTED_SUCCESS, {
          ...mixpanelProperties,
          privatePolicyId: createdPrivatePolicyId,
        });

        setHidePrivatePolicyFormHeader(true);
        setIsPrivatePolicyCreationSuccessful(true);
      }
    } catch (error) {
      setAlert("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onNewFiles = (field: ControllerRenderProps<PolicyDetailsFormValues, "attachment">, files: File[]) => {
    if (files.length === 0) {
      setError("attachment", {
        type: "manual",
        message: "There is a problem with your file. Verify the file is not too large and try again.",
      });
    } else if (files.length > 1) {
      setError("attachment", { type: "manual", message: "Only one file can be uploaded" });
    } else {
      field.onChange(files[0]);
    }
  };

  const onDeleteFile = (field: ControllerRenderProps<PolicyDetailsFormValues, "attachment">) => {
    field.onChange(null);
  };

  const onCancelButtonClick = () => {
    handleCancel(mixpanelTrack, navigate);
  };

  const onNeedHelpButtonClick = () => {
    mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_BUTTON_CLICKED, {
      currentPrivatePolicySubmissionStep,
      referrer: "Policy Details Alert",
    });
    setNeedHelpModalOpen(true);
  };

  const onManagePolicyButtonClick = () => {
    navigate("/manage-private-policies");
  };

  const onAddAnotherPolicyButtonClick = () => {
    setHidePrivatePolicyFormHeader(false);
    clearPrivatePolicyFormStore();
  };

  if (isPrivatePolicyCreationSuccessful)
    return (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" height="100%" width="100%">
        <CheckCircleIcon sx={{ fontSize: 36, color: "success.main" }} />
        <Typography sx={{ fontSize: 20, fontWeight: 500, pt: 1 }}>Thank you. Your policy has been submitted for review.</Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            color: (theme: Theme) => theme.palette.grey.A200,
            textWrap: "balance",
            pb: 1,
          }}
          textAlign="center"
        >
          To change or add another policy, see options below
        </Typography>
        <Stack direction="row" width="100%" pt={2}>
          <SafeLeaseButton
            onClick={onManagePolicyButtonClick}
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
          >
            Manage policy
          </SafeLeaseButton>
        </Stack>
        <Stack direction="row" width="100%" pt={2}>
          <SafeLeaseButton
            onClick={onAddAnotherPolicyButtonClick}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "white",
              color: "#277afb",
              border: "1px solid",
              borderColor: "#277afb",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#277afb",
                color: "white",
                border: "1px solid",
                borderColor: "#277afb",
              },
            }}
          >
            Add another policy
          </SafeLeaseButton>
        </Stack>
      </Stack>
    );

  if (!matchedLocation)
    return (
      <AlertBanner
        type="error"
        message="There is an error preventing you from submitting your policy. Please select a facility first."
        onClick={() => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_LOCATION)}
      />
    );

  if (!matchedUnit)
    return (
      <AlertBanner
        type="error"
        message="There is an error preventing you from submitting your policy. Please select a unit first."
        onClick={() => setCurrentPrivatePolicySubmissionStep(PrivatePolicySubmissionStepType.FIND_UNIT)}
      />
    );

  const attachment = watch("attachment");

  return (
    <FormProvider {...useFormMethods}>
      <form style={{ height: "100%" }}>
        <Stack spacing={3} width="100%" paddingBottom={{ xs: 2, sm: 0 }}>
          <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <VerifiedUserOutlinedIcon sx={{ fontSize: 32, color: "#277afb" }} />
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Proof of insurance</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <NeedHelpComponent />
            </Stack>
          </Stack>
          <Stack direction="row">
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Policy details
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: "grey.A200",
                }}
              >
                All fields are required.
              </Typography>
            </Stack>
          </Stack>
          {!!alert && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              marginY={1}
              padding={1}
              sx={{
                borderColor: "#e9645f",
                backgroundColor: "#fcefef",
                borderRadius: 1,
                cursor: "pointer",
                "&:hover": {
                  boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                },
              }}
              onClick={onNeedHelpButtonClick}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorIcon sx={{ color: "#e9645f" }} />
                <Typography variant="body2" sx={{ color: "#e9645f", textWrap: "pretty" }}>
                  {alert}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="caption" sx={{ color: "#e9645f", fontWeight: 600, textWrap: "nowrap" }}>
                  Get help
                </Typography>
              </Stack>
            </Stack>
          )}
          <Stack direction="column" spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} width="100%" spacing={{ xs: 1, sm: 2 }}>
              <StyledSafeLeaseFormTextField
                name="insuranceCompany"
                helperText={errors.insuranceCompany ? errors.insuranceCompany.message : ""}
                error={!!errors.insuranceCompany}
                label={
                  <>
                    Insurance provider
                    <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                      *
                    </Typography>
                  </>
                }
                placeholder="Enter provider name"
              />

              <StyledSafeLeaseFormTextField
                name="policyNumber"
                helperText={errors.policyNumber ? errors.policyNumber.message : ""}
                error={!!errors.policyNumber}
                label={
                  <>
                    Policy number
                    <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                      *
                    </Typography>
                  </>
                }
                placeholder="Enter policy number"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} width="100%" spacing={{ xs: 1, sm: 2 }}>
              <Stack direction="column" sx={{ width: { xs: "100%", sm: "50%" } }}>
                <Typography component="label" sx={{ fontSize: 14, fontWeight: 500 }}>
                  Policy deductible
                  <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                    *
                  </Typography>
                </Typography>
                <Controller
                  name="policyDeductible"
                  control={control}
                  render={({ field }) => (
                    <NumericFormatWrapper
                      {...field}
                      error={!!errors.policyDeductible}
                      placeholder="Enter total deductible"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid #ebeff7",
                            borderRadius: "8px",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid #ebeff7",
                          },
                          "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                            borderColor: "#277afb",
                          },
                        },
                        "& .MuiInputBase-input": {
                          padding: 1,
                        },
                      }}
                    />
                  )}
                />
                {errors.policyDeductible && (
                  <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                    {errors.policyDeductible.message}
                  </Typography>
                )}
              </Stack>
              <Stack direction="column" sx={{ width: { xs: "100%", sm: "50%" } }}>
                <Typography component="label" sx={{ fontSize: 14, fontWeight: 500 }}>
                  Expiration date
                  <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                    *
                  </Typography>
                </Typography>
                <DatePicker
                  value={watch("expiration") ? dayjs(watch("expiration")) : null}
                  onChange={(date: Dayjs | null) => setValue("expiration", date ? date.toDate() : null, { shouldValidate: true })}
                  slotProps={{
                    textField: {
                      placeholder: "Enter date",
                      fullWidth: true,
                      error: !!errors.expiration,
                      helperText: errors.expiration ? errors.expiration.message : "",
                      InputProps: {
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #ebeff7",
                            borderRadius: "8px",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #ebeff7",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #277afb",
                          },
                          "& .MuiInputBase-input": { padding: 1 },
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center">
                <IconButton onClick={handleProofOfInsuranceClick} sx={{ p: 0, mr: 1 }}>
                  <InfoOutlinedIcon sx={{ height: 24, width: 24, color: "#667085", "&:hover": { cursor: "pointer" } }} />
                </IconButton>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Proof of insurance (Declarations page)
                  <Typography component="span" color="error.main" sx={{ ml: 0.5 }}>
                    *
                  </Typography>
                </Typography>
              </Stack>
              <Popover
                open={!!proofOfInsuranceAnchorEl}
                anchorEl={proofOfInsuranceAnchorEl}
                onClose={handleProofOfInsuranceClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      maxWidth: "600px",
                    },
                  },
                }}
              >
                <Typography sx={{ p: 2 }}>
                  An insurance declarations page is a summary of key details in an insurance policy, including the policyholder's and
                  insurer's information, policy number, coverage type, and effective dates. It outlines coverage limits, deductibles,
                  premiums, and any endorsements or riders, as well as details of insured property and any lienholders or additional insured
                  parties. This document serves as a quick reference for verifying coverage and proving insurance to third parties.
                </Typography>
              </Popover>
              <Controller
                name="attachment"
                control={control}
                rules={{ required: "Declarations Page is required" }}
                render={({ field }) => (
                  <FileUploadComponent
                    accept={{
                      "application/pdf": [".pdf"],
                      "image/jpeg": [".jpeg", ".jpg"],
                      "image/png": [".png"],
                      "application/msword": [".doc", ".docx"],
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
                      "image/x-adobe-dng": [".dng"],
                      "image/heic": [".heic"],
                    }}
                    handleNewFiles={(files) => onNewFiles(field, files)}
                    uploadedFiles={attachment ? [attachment] : undefined}
                    onDeleteFile={() => onDeleteFile(field)}
                  />
                )}
              />
              {errors.attachment && (
                <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                  {errors.attachment.message}
                </Typography>
              )}
            </Stack>
            {!!matchedLocation?.tenantPolicyExpirationRemindersEnabled && (
              <Stack direction="row" alignItems="center">
                <Controller
                  name="policyExpirationRemindersEnabled"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label="Remind me 30 days before my policy expires"
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          icon={
                            <SvgIcon>
                              <rect width="16" height="16" x="4" y="4" rx="4" fill="white" stroke="currentColor" strokeWidth="2" />
                            </SvgIcon>
                          }
                          checkedIcon={
                            <SvgIcon>
                              <rect width="16" height="16" x="4" y="4" rx="4" fill="currentColor" />
                              <path d="M9 12l2 2 4-4" stroke="white" strokeWidth="2" fill="none" />
                            </SvgIcon>
                          }
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      slotProps={{
                        typography: {
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: 14,
                          fontWeight: 400,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            <SafeLeaseButton
              disabled={!isValid || loading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              fullWidth
              sx={{ backgroundColor: "#277afb", color: "white", borderRadius: "8px" }}
            >
              {loading ? <CircularProgress /> : "Finish & submit"}
            </SafeLeaseButton>
            <SafeLeaseButton
              onClick={onCancelButtonClick}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "white",
                color: "error.main",
                border: "1px solid",
                borderColor: "error.main",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "error.main",
                  color: "white",
                  border: "1px solid",
                  borderColor: "error.main",
                },
              }}
            >
              Cancel
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

/**
 * This is a wrapper around NumericFormat to surpress the warning:
 * Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
 * Check the render method of `Controller`.
 */
const NumericFormatWrapper = forwardRef((props: any, ref) => {
  return (
    <NumericFormat
      {...props}
      allowNegative={false}
      fixedDecimalScale
      thousandSeparator=","
      prefix="$"
      decimalScale={2}
      fullWidth
      customInput={TextField}
      getInputRef={ref}
    />
  );
});

export { PolicyDetailsForm };
