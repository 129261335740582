import { Chip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";

interface PrivatePolicyStatusChipProps {
  privatePolicy: SafeleasePrivatePolicy;
}
/**
 * Component that displays a chip with varying colors and labels based on the
 * status of the private policy.
 */
export function PrivatePolicyStatusChip({ privatePolicy }: PrivatePolicyStatusChipProps) {
  const { status } = getCalculatedPrivatePolicyStatus(privatePolicy);
  const { label, ...sx }: StatusConfig = statusConfigs[status];

  return (
    <Chip
      size="small"
      label={label}
      sx={{
        ...sx,
        borderRadius: 1,
        "& .MuiChip-label": {
          fontWeight: 400,
        },
      }}
    />
  );
}

// Status config visuals
type StatusConfig = {
  label: string;
  color: string;
  bgcolor: string;
};

const statusConfigs: {
  [key: string]: StatusConfig;
} = {
  accepted: {
    label: "Accepted",
    color: "white",
    bgcolor: "rgb(37, 155, 36)",
  },
  expiringSoon: {
    label: "Expiring Soon",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
  expired: {
    label: "Expired",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[100],
  },
  inReview: {
    label: "In Review",
    color: "white",
    bgcolor: "rgb(0, 123, 255)",
  },
  rejected: {
    label: "Rejected",
    color: "white",
    bgcolor: "rgb(233,100,95)",
  },
};
