import mixpanel from "mixpanel-browser";
import { UserAttributes } from "../hooks/useAuthStore";
import { appConfig } from "../config/app.config";
import * as Sentry from "@sentry/react";
import { version } from "../../package.json";

export const MIXPANEL_EVENTS = {
  // Storage Protectors Domain Viewed (used for claims) - https://storageprotectors.safelease.com/
  STORAGE_PROTECTORS_DOMAIN_VIEWED: "Storage Protectors Domain Viewed",

  // General Location Matching (used for claims)
  LOCATION_SEARCH_STEP_SUBMITTED: "Location Search Step Submitted",
  LOCATION_SEARCH_STEP_SUBMITTED_FAILED_TO_MATCH: "Location Search Step Submitted Failed to Match",

  // Private Policy Protection Plan Selection (used in manage private policies)
  PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_VIEWED: "Private Policy Protection Plan Selection Viewed",
  PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_PLAN_SELECTED: "Private Policy Protection Plan Selection Plan Selected",
  PRIVATE_POLICY_PROTECTION_PLAN_SELECTION_PLAN_SUBMITTED: "Private Policy Protection Plan Selection Plan Submitted",
  PRIVATE_POLICY_PROTECTION_PLAN_LAUNCHED_FROM_ARCHIVE_BUTTON_CLICKED:
    "Private Policy Protection Plan Launched From Archive Button Clicked",
  PRIVATE_POLICY_PROTECTION_PLAN_LAUNCHED_FROM_PROTECTION_PLAN_BUTTON_CLICKED:
    "Private Policy Protection Plan Launched From Protection Plan Button Clicked",

  // Private Policy Opt-Out Form
  PRIVATE_POLICY_OPT_OUT_FORM_VIEWED: "Private Policy Opt-Out Form Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_CANCELLED: "Private Policy Opt-Out Form Cancelled",

  // Private Policy Opt-Out Form - Need Help Dialog
  PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_BUTTON_CLICKED: "Private Policy Opt-Out Form Need Help Button Clicked",
  PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_VIEWED: "Private Policy Opt-Out Form Need Help Dialog Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_SUBMITTED: "Private Policy Opt-Out Form Need Help Dialog Submitted",
  PRIVATE_POLICY_OPT_OUT_FORM_NEED_HELP_DIALOG_DISMISSED: "Private Policy Opt-Out Form Need Help Dialog Dismissed",

  // Private Policy Opt-Out Form - Location Step
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_STEP_VIEWED: "Private Policy Opt-Out Form Location Step Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_AUTO_MATCH_SUCCESS: "Private Policy Opt-Out Form Location Auto Match Success",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_AUTO_MATCH_FAILED: "Private Policy Opt-Out Form Location Auto Match Failed",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_AUTO_MATCH_REJECTED_BY_USER: "Private Policy Opt-Out Form Location Auto Match Rejected By User",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_ATTEMPTED: "Private Policy Opt-Out Form Location Manual Match Attempted",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_SUCCESS: "Private Policy Opt-Out Form Location Manual Match Success",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_MANUAL_MATCH_FAILED: "Private Policy Opt-Out Form Location Manual Match Failed",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_ERROR_MESSAGE_SHOWN: "Private Policy Opt-Out Form Location Error Message Shown",
  PRIVATE_POLICY_OPT_OUT_FORM_LOCATION_STEP_SUBMITTED: "Private Policy Opt-Out Form Location Step Submitted",

  // Private Policy Opt-Out Form - Unit Step
  PRIVATE_POLICY_OPT_OUT_FORM_UNIT_STEP_VIEWED: "Private Policy Opt-Out Form Unit Step Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_UNIT_AUTO_MATCH_SUCCESS: "Private Policy Opt-Out Form Unit Auto Match Success",
  PRIVATE_POLICY_OPT_OUT_FORM_UNIT_AUTO_MATCH_FAILED: "Private Policy Opt-Out Form Unit Auto Match Failed",
  PRIVATE_POLICY_OPT_OUT_FORM_UNIT_STEP_SUBMITTED: "Private Policy Opt-Out Form Unit Step Submitted",

  // Private Policy Opt-Out Form - Select Coverage Step
  PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_VIEWED: "Private Policy Opt-Out Form Select Coverage Step Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_PLAN_SELECTED: "Private Policy Opt-Out Form Select Coverage Plan Selected",
  PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_PLAN_UNSELECTED: "Private Policy Opt-Out Form Select Coverage Plan Unselected",
  PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_SKIPPED: "Private Policy Opt-Out Form Select Coverage Step Skipped",
  PRIVATE_POLICY_OPT_OUT_FORM_SELECT_COVERAGE_STEP_SUBMITTED: "Private Policy Opt-Out Form Select Coverage Step Submitted",

  // Private Policy Opt-Out Form - Policy Details Step
  PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_VIEWED: "Private Policy Opt-Out Form Policy Details Step Viewed",
  PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMIT_ERROR: "Private Policy Opt-Out Form Policy Details Step Submit Error",
  PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMITTED: "Private Policy Opt-Out Form Policy Details Step Submitted",
  PRIVATE_POLICY_OPT_OUT_FORM_POLICY_DETAILS_STEP_SUBMITTED_SUCCESS: "Private Policy Opt-Out Form Policy Details Step Submitted Success",
};

if (appConfig.environment === "production" || appConfig.mixpanel.lowerEnvironmentEnabled) {
  mixpanel.init(appConfig.mixpanel.token);
  mixpanelDevLogger("Mixpanel Initialized");
}

function mixpanelDevLogger(message: string, ...args: any[]) {
  if (appConfig.environment !== "production" && appConfig.mixpanel.devLoggerEnabled) {
    console.log(`[Mixpanel]: ${message}`, ...args);
  }
}

export function mixpanelIdentify(userAttributes: UserAttributes): void {
  try {
    if (appConfig.environment === "production" || appConfig.mixpanel.lowerEnvironmentEnabled) {
      mixpanel.identify(userAttributes.sub);
      const userAttributesForMixpanel = getUserAttributesForMixpanelProperties(userAttributes);

      mixpanel.people.set({
        ...userAttributesForMixpanel,
        $name: `${userAttributesForMixpanel.given_name} ${userAttributesForMixpanel.family_name?.substring(0, 1)}`,
        $email: userAttributesForMixpanel.email,
      });

      mixpanel.register({
        ...userAttributesForMixpanel,
        ...getSystemContext(),
      });
      mixpanelDevLogger(`Mixpanel Identified - ${userAttributes.sub}`);
    }
  } catch (error) {
    console.error(`Error identifying user in Mixpanel: ${error}`);
    Sentry.captureException(error);
  }
}

export function mixpanelEventHandler(event: string, properties?: object): void {
  if (appConfig.environment !== "production") {
    mixpanelDevLogger(`${!appConfig.mixpanel.lowerEnvironmentEnabled ? "Skip" : ""} Event Tracked - `, event, properties);
    if (appConfig.mixpanel.lowerEnvironmentEnabled) {
      mixpanel.track(event, properties);
    }
  } else {
    mixpanel.track(event, properties);
    mixpanelDevLogger(`Event Tracked - ${event}`);
  }
}

export function mixpanelReset(): void {
  mixpanel.reset();
  mixpanelDevLogger("Reset");
}

function getSystemContext() {
  return {
    packageVersion: version,
    environment: appConfig.environment,
  };
}

function getUserAttributesForMixpanelProperties(userAttributes: UserAttributes) {
  const mixpanelProperties: any = {};

  const userAttributesToSkip = ["phone_number", "family_name"];
  if (userAttributes) {
    for (const [key, val] of Object.entries(userAttributes)) {
      if (!userAttributesToSkip.includes(key)) {
        mixpanelProperties[key] = val;
      }
    }
  }

  return {
    ...mixpanelProperties,
    name: `${userAttributes.given_name} ${userAttributes.family_name?.substring(0, 1)}`,
  };
}
